import React from "react";
import { Spin } from "antd";
import { setup, configure } from "@eitje/form";
import { t } from "initializers/i18n";
import utils from "@eitje/web_utils";

const DefaultInput = ({ onChange, field }) => (
  <input onChange={(e) => onChange(e.target.value)} />
);

setup({ translatorFunc: t, alertFunc: utils.alert, Wrapper: "form" });

const defaultProps = { DefaultInput, LoadingEl: () => <Spin spinning /> };

const fieldRules = {
  naam: (value) => value === "baas",
};

const fieldMessages = {
  naam: "invalid name",
};

const messages = { field: fieldMessages };
const rules = { field: fieldRules };

const Form = configure({ rules, messages, defaultProps });
export default Form;
