import ActiveRecord from "models/active_record/base";
import { useWhere as doWhere, useFind, useAll } from "@eitje/easy_api";
import { useSelector as doSelector } from "hooks/use_selector";
class Outreach extends ActiveRecord {
  static tableName = "outreaches";
  static defaultJoins = ["campaigns"];

  constructor(props) {
    super(props);
    this.start = this.start.bind(this);
  }

  start(params) {
    return this.resourceReq("start", params);
  }
}

export default Outreach;
