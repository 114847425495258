import { Drawer as AntDrawer } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
const Drawer = (props) => {
  let navigate = useNavigate();

  return (
    <AntDrawer
      visible
      width={600}
      contentWrapperStyle={{ width: 600 }}
      push={{ distance: 600 }}
      onClose={() => navigate(-1)}
      {...props}
    />
  );
};

export default Drawer;
