import React, { useEffect } from "react";
import { Route, Navigate, Outlet, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { API } from "@eitje/easy_api";
import { useForm, ContextForm } from "@eitje/form";
import { DropdownPicker, Input } from "@eitje/form-fields-web";
import _ from "lodash";
import SimpleList from "components/simple_list";
import { useBoolState } from "@eitje/react-hooks";
import { Button } from "components/common";
import { FormSubmitButton } from "components/form";
import moment from "moment";
import { Contact, ReceivedMail, PlannedMail, SentMail } from "models/index";
import useShow from "hooks/use_show";

const fetch = (contactId) => {
	const params = { filters: { contact_id: contactId } };
	API.index("planned_mails", params);
	API.index("sent_mails", params);
	API.index("received_mails", params);
};

const useContactMails = (contactId) => {
	useEffect(() => {
		fetch(contactId);
	}, [contactId]);

	const allMails = useContactMailsLocal(contactId);
	return allMails;
};

const useContactMailsLocal = (contactId) => {
	const params = { contact_id: contactId };
	const plannedMails = PlannedMail.where(params);
	const receivedMails = ReceivedMail.where(params);
	const sentMails = SentMail.where(params);

	const allMails = _.orderBy(
		[...plannedMails, ...receivedMails, ...sentMails],
		(m) => m.sortTime(),
		["desc"]
	);

	return allMails;
};

export { fetch, useContactMails, useContactMailsLocal };
