import ActiveRecord from "models/active_record/base";
import {
	useWhere,
	useFind,
	useAll,
	useSelector as doSelector,
} from "@eitje/easy_api";
import { ReceivedMail, FollowUp, PlannedMail, SentMail } from "models/index";

class Contact extends ActiveRecord {
	static tableName = "contacts";
	static defaultJoins = ["emails"];
	constructor(props) {
		super(props);
		this.statusChangeAllowed = this.statusChangeAllowed.bind(this);
		this.approachable = this.approachable.bind(this);
	}

	statusChangeAllowed = () => {
		return !(this.status == "blacklisted");
	};
	approachable = this.statusChangeAllowed;

	emailBlacklisted = (email) => {
		const { blacklisted_email_addresses = [] } = this;
		return blacklisted_email_addresses.includes(email);
	};

	cancelPlannedMails = async () => {
		return await this.resourceReq("cancel_planned_mails");
	};
}

export default Contact;
