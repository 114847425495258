import { backend } from "initializers/api";
import { success } from "utils/ui";
import { store, history } from "index";

export async function login({ email, password }) {
  const body = { email, password, grant_type: "password", scope: "user" };
  const resp = await backend.post("oauth/token", body);
  if (!resp.problem) {
    const { data } = resp;
    const obj = {
      token: data.access_token,
      expires_at: data.created_at + data.expires_in,
      user: data.user,
      refresh_token: data.refresh_token,
    };
    store.dispatch({ type: "AUTH_SUCCESS", ...obj });
    history.push("/");
    return obj;
  }
}

export async function signUp(
  email,
  password,
  first_name,
  last_name,
  type,
  store
) {
  const body = {
    email,
    password,
    first_name,
    last_name,
    password_confirmation: password,
  };
  const resp = await backend.post(`users`, { user: body });
  if (!resp.problem) {
    await login();
    //await call(login, email, password, null )
  }
}

export async function refreshToken() {
  const { auth } = store.getState();
  const prms = {
    scope: "user",
    refresh_token: auth.refresh_token,
    grant_type: "refresh_token",
  };
  const resp = await backend.post(`oauth/token`, prms);
  const { data } = resp;
  if (!resp.problem) {
    store.dispatch({
      type: "SET_TOKENS",
      token: data.access_token,
      refresh_token: data.refresh_token,
      expires_at: data.created_at + data.expires_in,
    });
    return {
      ...data,
      access_token: data.access_token,
      expires_at: data.created_at + data.expires_in,
    };
  } else {
    logout();
    return false;
  }
}

export async function refreshTokenIfNeeded() {
  const cs = Math.round(new Date().getTime() / 1000);
  const state = store.getState();
  if (state.auth.refresh_token && state.auth.expires_at < cs) {
    const tokenData = await refreshToken();
    return tokenData.access_token;
  } else {
    return state.auth.token;
  }
}

export async function handleRegistration(user, password) {
  login(user.email, password);
  success();
}

export function checkAuth() {
  const state = store.getState();
  return !!state.auth.refresh_token;
}

export async function logout() {
  store.dispatch({ type: "LOGOUT" });
  store.dispatch({ type: "RESET_RECORDS" });
}
