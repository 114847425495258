import React, { Fragment, useState, useEffect } from "react";
import {
	Route,
	Navigate,
	Outlet,
	useParams,
	useNavigate,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { API } from "@eitje/easy_api";
import { useForm, ContextForm } from "@eitje/form";
import { DropdownPicker, Input } from "@eitje/form-fields-web";
import _ from "lodash";
import SimpleList from "components/simple_list";
import { useBoolState } from "@eitje/react-hooks";
import { FormSubmitButton } from "components/form";
import moment from "moment";
import {
	Contact,
	ReceivedMail,
	FollowUp,
	PlannedMail,
	SentMail,
} from "models/index";
import useShow from "hooks/use_show";
import { ModalLink, NavLink, Link } from "components/routing";
import { Button, ConfirmButton } from "components/common";
import Modal from "components/modal";
import { DrawerContents } from "cores/contacts/index";
import "./styles/index.less";

const ReMap = () => {
	const { item: mail } = useShow("receivedMails", { local: true });
	const currentContact = Contact.find(mail.contact_id);
	const [selectedContactId, selectContactId] = useState();
	const { item: selectedContact } = Contact.show({
		id: selectedContactId,
		local: !selectedContactId,
	});
	const contacts = Contact.all();
	const navigate = useNavigate();

	const remap = async () => {
		const res = await mail.remap(selectedContactId);
		if (res.ok) {
			navigate(-1);
		}
	};

	return (
		<Modal wrapClassName="remap-modal" width={1200}>
			<div className="remap-modal-header">
				<h3> Map contact </h3>

				<div className="remap-modal-header-right">
					<DropdownPicker
						items={contacts}
						value={selectedContact}
						onChange={selectContactId}
					/>
					<Button onClick={remap}>map</Button>
				</div>
			</div>
			<MapData item={currentContact} />
			{selectedContact.id && <MapData item={selectedContact} />}
		</Modal>
	);
};

const MapData = ({ item }) => {
	if (!item.id)
		return (
			<div className="remap-modal-column">
				<h4> Not mapped yet.. </h4>
			</div>
		);
	return (
		<div className="remap-modal-column">
			<DrawerContents item={item} />
		</div>
	);
};

export default ReMap;
