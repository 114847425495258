import {
	useWhere as getWhere,
	useFind as getFind,
	useAll as getAll,
} from "@eitje/easy_api";

const queryMethodsMixin = (Base) =>
	class extends Base {
		static all() {
			return getAll(this.tableName);
		}
		static where(query) {
			return getWhere(this.tableName, query);
		}
		static find(query) {
			return getFind(this.tableName, query);
		}
	};

export default queryMethodsMixin;
