import React, { Fragment, useState, useEffect } from "react";
import { Route, Navigate, Outlet, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { API, useIndex } from "@eitje/easy_api";
import { useForm, ContextForm } from "@eitje/form";
import { DropdownPicker, Input } from "@eitje/form-fields-web";
import _ from "lodash";
import SimpleList from "components/simple_list";
import { useBoolState } from "@eitje/react-hooks";
import { FormSubmitButton } from "components/form";
import moment from "moment";
import {
	Contact,
	ReceivedMail,
	FollowUp,
	PlannedMail,
	SentMail,
	InternalBlacklist,
	Campaign,
	Outreach,
} from "models/index";
import useShow from "hooks/use_show";
import { ModalLink, NavLink, Link, LinkBtn } from "components/routing";
import { Button, ConfirmButton } from "components/common";
import Modal from "components/modal";
import utils from "@eitje/web_utils";
import { MultiEmailInput } from "components/multi_email_input";
import "./styles/settings_page_mixin.less";

const Spamlist = () => {
	const [emails, setEmails] = useState([]);
	const items = InternalBlacklist.index();
	return (
		<div className="spam-list-page settings-page-mixin">
			<ContextForm
				resetAfterSubmit
				onSubmit={({ emails }) => createSpamlist(emails)}
			>
				<MultiEmailInput
					submitStrategy="change"
					field="emails"
					label="spam emails"
				/>
			</ContextForm>
			<SimpleList searchField="email" items={items} Item={SpamlistItem} />
		</div>
	);
};

const createSpamlist = (emails) => {
	const items = emails.map((e) => ({ email: e }));
	return API.arbitrary("internalBlacklist", "multi_create", { items });
};

const SpamlistItem = ({ item }) => (
	<div className="settings-page-list-item">
		<p> {item.email} </p>{" "}
		<ConfirmButton onClick={() => item.destroy()}> X </ConfirmButton>{" "}
	</div>
);

export default Spamlist;
