import React, { useEffect } from "react";
import { Route, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { API } from "@eitje/easy_api";
import { useForm, ContextForm } from "@eitje/form";
import { DropdownPicker, Input } from "@eitje/form-fields-web";
import _ from "lodash";
import SimpleList from "components/simple_list";
import { useBoolState } from "@eitje/react-hooks";
import { Button } from "components/common";
import { FormSubmitButton } from "components/form";
import "./styles/emails.less";

const Emails = ({ item }) => {
	const { emails } = item;
	const [emailFormVisible, toggleEmailFormVisible] = useBoolState();

	return (
		<div className="emails">
			<h4 className="simple-list-header"> Emails </h4>
			<SimpleList items={emails} />

			<Button
				className="poached-secondary-button button-add-email"
				onClick={toggleEmailFormVisible}
			>
				add
			</Button>

			{emailFormVisible && (
				<EmailForm id={item.id} hide={toggleEmailFormVisible} />
			)}
		</div>
	);
};
const EmailForm = ({ hide, id }) => {
	return (
		<ContextForm
			afterSubmit={hide}
			onSubmit={(data) =>
				API.create("emails", {
					...data,
					contact_id: id,
				})
			}
		>
			<Input required field="email" />
			<FormSubmitButton className="poached-secondary-button" />
		</ContextForm>
	);
};

export default Emails;
