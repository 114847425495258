import React, { Fragment, useState, useEffect } from "react";
import { Route, Navigate, Outlet, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { API, useIndex } from "@eitje/easy_api";
import { useForm, ContextForm } from "@eitje/form";
import { DropdownPicker, Input } from "@eitje/form-fields-web";
import _ from "lodash";
import SimpleList from "components/simple_list";
import { useBoolState } from "@eitje/react-hooks";
import { FormSubmitButton } from "components/form";
import moment from "moment";
import {
	Contact,
	ReceivedMail,
	FollowUp,
	PlannedMail,
	SentMail,
	Campaign,
	Outreach,
} from "models/index";
import useShow from "hooks/use_show";
import { ModalLink, NavLink, Link, LinkBtn } from "components/routing";
import { Button, ConfirmButton } from "components/common";
import Modal from "components/modal";
import utils from "@eitje/web_utils";
import MultiTextInput, { MultiEmailInput } from "components/multi_email_input";

const Blacklist = () => {
	const [emails, setEmails] = useState([]);
	const items = useIndex("blacklists");
	return (
		<div className="blacklist-page settings-page-mixin">
			<ContextForm
				resetAfterSubmit
				onSubmit={({ emails }) => createBlacklist(emails)}
			>
				<MultiTextInput
					submitStrategy="change"
					field="emails"
					label="blacklists"
				/>
			</ContextForm>
			<SimpleList searchField="listing" items={items} Item={BlacklistItem} />
		</div>
	);
};

const createBlacklist = (emails) => {
	emails = emails.filter(Boolean);
	const items = emails.map((e) => ({
		listing: e,
		listing_type: e.includes("@") ? "email" : "domain",
	}));
	return API.arbitrary("blacklists", "multi_create", { items });
};

const BlacklistItem = ({ item }) => (
	<p className="settings-page-list-item"> {item.listing} </p>
);

export default Blacklist;
