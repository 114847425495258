import React, { useEffect } from "react";
import { Route, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { API } from "@eitje/easy_api";
import { useForm, ContextForm } from "@eitje/form";
import { DropdownPicker, Input } from "@eitje/form-fields-web";
import _ from "lodash";
import "./styles/simple_list.less";
import useSearch from "hooks/use_search";

const SimpleList = ({
	searchField,
	items = [],
	itemProps = {},
	Item = DefaultItem,
}) => {
	const { searchInput, filteredItems } = useSearch(items, {
		searchField,
	});
	return (
		<div className="simple-list">
			{searchField && searchInput}
			{filteredItems.map((i) => (
				<Item {...itemProps} item={i} />
			))}
		</div>
	);
};

const DefaultItem = ({ item }) => {
	return (
		<div className="simple-list-item">
			<p className="simple-list-item-content"> {item}</p>
		</div>
	);
};

export default SimpleList;
