import React, { useEffect } from "react";
import { Route, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { API } from "@eitje/easy_api";
import { useForm, ContextForm } from "@eitje/form";
import { DropdownPicker, Input } from "@eitje/form-fields-web";
import Drawer from "components/drawer";
import useShow from "hooks/use_show";
import SimpleTable from "components/simple_table";
import SimpleList from "components/simple_list";
import _ from "lodash";
import { useBoolState } from "@eitje/react-hooks";
import { Button } from "components/common";
import { useLocation } from "react-router-dom";

const FormSubmitButton = (props) => {
	let { children = "submit", text } = props;
	const { submit } = useForm();
	if (text) children = text;
	return <Button onClick={submit} {...props} children={children}></Button>;
};

const LocationResetter = () => {
	const { resetValues } = useForm();
	const loc = useLocation();
	useEffect(() => {
		resetValues();
	}, [loc]);

	return null;
};

export { FormSubmitButton, LocationResetter };
