import React, { Fragment, useEffect, useState } from "react";
import { Route, Navigate, Outlet, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { API } from "@eitje/easy_api";
import { useForm, ContextForm } from "@eitje/form";
import { DropdownPicker, Input } from "@eitje/form-fields-web";
import _ from "lodash";
import SimpleList from "components/simple_list";
import SimpleGroupedList from "components/simple_grouped_list";
import { useBoolState, useConcatState } from "@eitje/react-hooks";
import { FormSubmitButton } from "components/form";
import moment from "moment";
import {
  Contact,
  ReceivedMail,
  FollowUp,
  PlannedMail,
  SentMail,
  Campaign,
  Snippet,
  Outreach,
} from "models/index";
import useShow from "hooks/use_show";
import { ModalLink, NavLink, Link, LinkBtn } from "components/routing";
import { Button, ConfirmButton } from "components/common";
import Modal from "components/modal";
import utils from "@eitje/web_utils";
import "./styles/snippets.less";

const Snippets = () => {
  const [snippet, setSnippet] = useState(false);
  const [customLabels, addCustomLabel] = useConcatState([]);
  const snippets = Snippet.all();
  const labels = _.uniq(snippets.map((i) => i.label)).filter(Boolean);
  const allLabels = [...labels, ...customLabels];
  return (
    <div className="snippets-page settings-page-mixin">
      <ContextForm
        overrideInitialValues
        initialValues={snippet}
        resetAfterSubmit
        onSubmit={(data) => Snippet.create(data)}
      >
        <Input required field="name" />
        <DropdownPicker
          dropdownRender={(menu) => renderDropdown(menu, addCustomLabel)}
          required
          field="label"
          items={allLabels}
        />
        <Input textarea required field="body" />
        <FormSubmitButton
          className="button-submit-snippet poached-secondary-button"
          text="submit"
        />
      </ContextForm>

      <SimpleGroupedList
        items={snippets}
        Item={SnippetItem}
        itemProps={{ setSnippet }}
      />
    </div>
  );
};

const SnippetItem = ({ item, setSnippet }) => {
  const { body, name, in_use } = item;
  const text = in_use ? "deactivate" : "activate";
  const submit = (e) => {
    e.stopPropagation();
    item.update({ in_use: !in_use });
  };

  const _setSnippet = (e) => {
    e.stopPropagation();
    setSnippet(item);
  };
  return (
    <div className="settings-page-list-item" onClick={_setSnippet}>
      <p className="snippet-title"> {name} </p>
      <Button
        onClick={submit}
        className="button-snippet-status poached-secondary-button "
      >
        {text}
      </Button>
    </div>
  );
};

const renderDropdown = (menu, addName) => {
  return (
    <>
      {menu}
      <ContextForm onSubmit={(data) => addName(data.name)}>
        <Input field="name" />
        <FormSubmitButton />
      </ContextForm>
    </>
  );
};

export default Snippets;
