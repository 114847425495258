import React, {
  useEffect,
  useState,
  Fragment,
  useCallback,
  useRef,
} from "react";
import useSelector from "hooks/use_selector";
import moment from "moment";
import {
  DropdownPicker,
  Input,
  DatePicker,
  Switch,
} from "@eitje/form-fields-web";
import { API, useWhere, useFind, useAll } from "@eitje/easy_api";
import { useParams } from "react-router-dom";
import { useAsyncEffect } from "@eitje/react-hooks";

const useShow = (kind, { idField = "id", id, local } = {}) => {
  const [isLoading, setLoading] = useState(!local);
  const params = useParams();
  if (!id) id = params[idField];
  const item = useFind(kind, id);
  useAsyncEffect(async () => {
    const extraParams = item?.fetchedKinds?.includes("show")
      ? { lastUpdatedStamp: item.updated_at }
      : {};

    !local &&
      (await API.show(kind, id, {
        extraParams,
      }));
    setLoading(false);
  }, [id]);
  return { item, isLoading };
};

export default useShow;
