import React, { useEffect, Fragment } from "react";
import { Route, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { API } from "@eitje/easy_api";
import { useForm, ContextForm } from "@eitje/form";
import { DropdownPicker, Input } from "@eitje/form-fields-web";
import Drawer from "components/drawer";
import useShow from "hooks/use_show";
import SimpleTable from "components/simple_table";
import SimpleList from "components/simple_list";
import _ from "lodash";
import { Button, ConfirmButton } from "components/common";
import { FormSubmitButton } from "components/form";
import Emails from "./components/emails";
import Notes from "./components/notes";
import Audits from "./components/audits";
import moment from "moment";
import { useContactMails, Mails } from "cores/mail/index";
import "./styles/contact_drawer.less";
import "./styles/conversation_drawer.less";
import { useBoolState } from "@eitje/react-hooks";

const infoFields = ["name", "status", "first_name", "outreaches"];

const Hello = () => {
	const [drawerV, toggleDrawerV] = useBoolState();
	const { item } = useShow("contacts");
	const mails = useContactMails(item.id);
	const anyPlanned =
		mails.filter((m) => m.tableName == "plannedMails").length > 0;

	return (
		<Drawer className="contact-drawer" width="35%">
			{mails.length > 0 && (
				<Button className="button-show-contact-mails" onClick={toggleDrawerV}>
					Show mails
				</Button>
			)}
			<ConfirmButton onClick={() => item.resourceReq("hubspot")}>
				Hubspot
			</ConfirmButton>
			<ConfirmButton onClick={() => item.resourceReq("blacklist")}>
				Blacklist
			</ConfirmButton>

			<DrawerContents item={item} />
			{drawerV && (
				<Drawer className="conversation-drawer" width="35%">
					{anyPlanned && (
						<ConfirmButton
							className="button-cancel-planned-mails"
							onClick={() => item.cancelPlannedMails()}
						>
							cancel planned mails
						</ConfirmButton>
					)}
					<Mails mails={mails} />
				</Drawer>
			)}
		</Drawer>
	);
};

const omitFields = [
	"name",
	"mails",
	"notes",
	"audits",
	"tableName",
	"activeRecordDescendant",
	"fetchedKinds",
];

export const DrawerContents = ({ item }) => {
	const { name, sent_mail_times = [] } = item;
	const contactInfo = _.omit(item, omitFields);
	return (
		<div className="drawer-contents">
			<div className="contact-info">
				<h2> {name} </h2>
				<h4 className="simple-table-header"> Contact </h4>
				<SimpleTable item={contactInfo} />
				<Emails item={item} />

				<h4 className="simple-list-header"> Sent mails </h4>
				<SimpleList
					items={sent_mail_times.map((t) =>
						moment(t).format("HH:mm on dddd DD/MM/YY")
					)}
				/>
			</div>
			<Audits item={item} />
			<Notes item={item} />
		</div>
	);
};

export default Hello;
