import React, { useEffect, useState, Fragment, useRef } from "react";
import { useSelector as useReduxSelector } from "react-redux";
import moment from "moment";
import Form from "initializers/form";
import { DropdownPicker, Input, DatePicker } from "@eitje/form-fields-web";
import { useWhere, useFind, useAll } from "@eitje/easy_api";
import { useMergeState } from "@eitje/react-hooks";

const useSelector = (selector, ...args) => {
  if (!selector) throw Error("No selector passed to useSelector");
  return useReduxSelector((state) => selector(state, ...args));
};

export default useSelector;
export { useSelector };
