import { notification, message } from "antd";

export const errNotif = (msg, descr) => {
  notification["error"]({
    message: msg,
    description: descr,
  });
};

export const success = () => {
  message.success("Great work bruv!", 1);
};
