import React, { Fragment, useEffect } from "react";
import { Route, Navigate, Outlet, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { API, useGet } from "@eitje/easy_api";
import { useForm, ContextForm } from "@eitje/form";
import { DropdownPicker, Input } from "@eitje/form-fields-web";
import _ from "lodash";
import SimpleList from "components/simple_list";
import { useBoolState } from "@eitje/react-hooks";
import { FormSubmitButton } from "components/form";
import moment from "moment";
import {
	Contact,
	ReceivedMail,
	FollowUp,
	PlannedMail,
	SentMail,
} from "models/index";
import useShow from "hooks/use_show";
import { ModalLink, NavLink, Link } from "components/routing";
import { Button, ConfirmButton } from "components/common";
import Modal from "components/modal";
import utils from "@eitje/web_utils";
import SimpleGroupedList from "components/simple_grouped_list";
import { useSendMailsContext } from "mixins/send_mails/index";
import "./styles/single_mails.less";

const Hello = () => {
	const { id } = useParams();
	const { data = {} } = useGet(`contacts/${id}/single_mails`);
	const { items } = data;
	return (
		<div className="single-mails">
			<h4 className="grouped-list-title"> Mails </h4>
			<SimpleGroupedList items={items} Item={FollowUpItem} />
		</div>
	);
};

const FollowUpItem = ({ item }) => {
	const { multiForm } = useSendMailsContext();
	const { name, template_mails } = item;
	const click = (e) => {
		e.stopPropagation();
		const mail = template_mails[0];
		multiForm.current.addForm();
		setTimeout(() => {
			multiForm.current.setValuesAtIndex(
				mail,
				multiForm.current.state.forms.length - 1
			);
		}, 10);
	};
	return (
		<div onClick={click}>
			<p> {name} </p>
		</div>
	);
};

export default Hello;
