import utils from "@eitje/web_utils";
import { Button, Popconfirm } from "antd";
import _ from "lodash";

const ConfirmButton = ({
	title = "Are you sure?",
	onClick,
	className,
	afterConfirm = _.noop,
	overlayClassName = "veedree",
	...rest
}) => {
	const { disabled } = rest;
	const classNames = utils.makeCns("eitje-confirm-button", className);

	const _onClick = async () => {
		const res = await onClick();
		if (res.ok) afterConfirm();
	};
	return (
		<Popconfirm
			disabled={disabled}
			overlayClassName={overlayClassName}
			title={title}
			onConfirm={_onClick}
		>
			<Button className={classNames} {...rest} />
		</Popconfirm>
	);
};

const AddButton = (props) => (
	<Button className="add-button" {...props}>
		<img src={"/images/icons/plus.png"} className="add-icon" /> Add
	</Button>
);

AddButton.__ANT_BUTTON = true;

ConfirmButton.__ANT_BUTTON = true;

export { Button, ConfirmButton, AddButton };
