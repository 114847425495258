import { backend } from "@eitje/easy_api";
import { store } from "index";

const getTaxonomy = async () => {
	const res = await backend.get("taxonomy");
	if (res.ok) {
		store.dispatch({ type: "GET_TAXONOMY", taxonomy: res.data });
	}
};
export { getTaxonomy };
