import ActiveRecord from "models/active_record/base";
import {
  useWhere,
  useFind,
  useAll,
  useSelector as doSelector,
} from "@eitje/easy_api";

class FollowUp extends ActiveRecord {
  static tableName = "followUps";
}

export default FollowUp;
