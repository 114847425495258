import React, { Fragment, useEffect, useState } from "react";
import { Route, Navigate, Outlet, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { API } from "@eitje/easy_api";
import { useForm, ContextForm } from "@eitje/form";
import { DropdownPicker, Input } from "@eitje/form-fields-web";
import _ from "lodash";
import SimpleList from "components/simple_list";
import { useBoolState } from "@eitje/react-hooks";
import { FormSubmitButton } from "components/form";
import moment from "moment";
import {
  Contact,
  ReceivedMail,
  FollowUp,
  PlannedMail,
  SentMail,
  Campaign,
  Outreach,
} from "models/index";
import useShow from "hooks/use_show";
import { ModalLink, NavLink, Link, LinkBtn } from "components/routing";
import { Button, ConfirmButton } from "components/common";
import Modal from "components/modal";
import utils from "@eitje/web_utils";
import useTaxonomy from "hooks/use_taxonomy";
import SimpleInputNumber from "components/simple_input_number";
import useGet from "hooks/use_get";
import "./styles/start_outreach.less";

const StartOutreach = ({ item }) => {
  return (
    <div className="start-outreach">
      <h4> Start outreach </h4>
      <ContextForm
        resetAfterSubmit
        onSubmit={(data) => item.start(data)}
        initialValues={{ business_type: "hospitality", country: "netherlands" }}
      >
        <InnerForm item={item} />
      </ContextForm>
    </div>
  );
};

const InnerForm = ({ item }) => {
  const { id } = item;

  const tax = useTaxonomy();
  const businessTypes = tax?.enums?.Contact?.business_type || [];
  const { getData } = useForm();
  const { business_type, status, country } = getData();
  const { data } = useGet(`outreaches/${id}/available_contacts`, {
    watch: `${status}-${business_type}-${country}`,
    shouldFetch: status && business_type,
    params: { data: { status, business_type, country } },
  });
  const { count } = data?.data || {};

  return (
    <Fragment>
      <DropdownPicker
        required
        field="business_type"
        label="business type"
        items={businessTypes}
      />
      <DropdownPicker
        required
        field="status"
        label="status"
        items={["dead", "unapproached"]}
      />
      <DropdownPicker
        required
        field="country"
        label="country"
        items={["netherlands", "belgium", "england"]}
      />
      {utils.exists(count) && (
        <h4 className="display-available-contacts">
          {count} available contacts
        </h4>
      )}
      <SimpleInputNumber
        disabled={!count}
        required
        min={1}
        max={count}
        field="amount"
        label="Contacts"
      />
      <FormSubmitButton
        className="poached-secondary-button"
        disabled={!count}
        text="start"
      />
    </Fragment>
  );
};

export default StartOutreach;
