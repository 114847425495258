import { makeField, useFormField } from "@eitje/form";
import useHotkey from "hooks/use_hotkey";
import React, {
  useEffect,
  useState,
  Fragment,
  useCallback,
  useRef,
} from "react";
import useSelector from "hooks/use_selector";
import moment from "moment";
import {
  DropdownPicker,
  DatePicker,
  BaseInput as Input,
  Switch,
} from "@eitje/form-fields-web";
import { API, useWhere, useFind, useAll } from "@eitje/easy_api";
import { useMergeState } from "@eitje/react-hooks";
import utils from "@eitje/web_utils";
import SelectedArea from "components/selected_area";
import _ from "lodash";
import { AddButton } from "components/common";

let MultiTextInput = (props) => {
  const {
    onChange = _.noop,
    isValid = (e) => true,
    value = [],
    placeholder,
  } = props;
  const [text, setText] = useState("");
  const add = () => {
    if (text) {
      const entries = parseText(text);
      const validEntries = entries.filter((e) => isValid(e));
      const invalidEntries = entries
        .filter((e) => !isValid(e))
        .filter((e) => utils.exists(e));
      if (invalidEntries.length > 0) {
        utils.errorNotif({
          description: `Invalid emails: ${invalidEntries.join(", ")}`,
        });
      }
      onChange([...value, ...validEntries]);
      setText(invalidEntries.join(","));
    }
  };

  const onKeyDown = useHotkey({ enter: add });

  return (
    <div>
      <Input
        onKeyDown={onKeyDown}
        value={text}
        onChange={setText}
        suffix={<AddButton onClick={add} />}
        placeholder={placeholder}
      />
      {false && (
        <SelectedArea
          action={(newVal) => onChange(value.filter((v) => v != newVal))}
          items={value}
        />
      )}
      {/* we dont need selectedarea for now*/}
    </div>
  );
};

const parseText = (text) => {
  return text.split(/,| /);
};

MultiTextInput = makeField(MultiTextInput, {
  className: "eitje-multi-text-input",
});
export default MultiTextInput;

export const MultiEmailInput = (props) => (
  <MultiTextInput
    placeholder="Add emails"
    {...props}
    isValid={utils.isEmail}
    bla="test"
  />
);
