import React, {
  useEffect,
  useState,
  Fragment,
  useCallback,
  useRef,
} from "react";
import moment from "moment";
import { DropdownPicker, Input, DatePicker } from "@eitje/form-fields-web";
import { API, useWhere, useFind, useAll } from "@eitje/easy_api";
import { useMergeState } from "@eitje/react-hooks";
import utils from "@eitje/web_utils";
import { InputNumber } from "antd";
import { makeField } from "@eitje/form";
import _ from "lodash";

const SimpleInputNumber = (props) => {
  const { onChange, min, max, disableLoop, placeholder = "..", value } = props;
  const allowDecrease =
    !utils.exists(min) || !utils.exists(value) || value > min;
  const allowIncrease =
    !utils.exists(max) || !utils.exists(value) || value < max;

  const decrease = () => {
    if (!disableLoop) {
      if (!utils.exists(value)) return onChange(max);
      if (value <= min) return onChange(null);
    }
    allowDecrease && onChange(value - 1);
  };

  const increase = () => {
    if (!disableLoop && value >= max) return onChange(null);
    if (!allowIncrease) return;
    onChange(utils.exists(value) ? value + 1 : min);
  };

  return (
    <div className="eitje-simple-input-number">
      <img
        onClick={decrease}
        src={"/images/web/icons/soft-right-pointer.png"}
        width={5}
        height={8}
      />
      <InputNumber bordered={false} placeholder={placeholder} {...props} />
      <img
        onClick={increase}
        src={"/images/web/icons/soft-right-pointer.png"}
        width={5}
        height={8}
      />
    </div>
  );
};

export default makeField(SimpleInputNumber, {
  className: "eitje-input-number",
});
