import React, { useEffect, useState } from "react";
import { Route, Navigate, Outlet, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { API } from "@eitje/easy_api";
import { useForm, ContextForm } from "@eitje/form";
import { DropdownPicker, Input } from "@eitje/form-fields-web";
import _ from "lodash";
import SimpleList from "components/simple_list";
import { useBoolState } from "@eitje/react-hooks";
import { FormSubmitButton } from "components/form";
import moment from "moment";
import { Contact, ReceivedMail, PlannedMail, SentMail } from "models/index";
import useShow from "hooks/use_show";
import { ModalLink } from "components/routing";
import { Button, ConfirmButton } from "components/common";
import { useContactMails } from "./helpers";
import { formatTime } from "utils/utils";
import { store } from "index";
import "./styles/mails.less";

const Mails = ({ mails = [] }) => {
	return (
		<div className="conversation-list">
			<SimpleList items={mails} Item={Mail} />
		</div>
	);
};

const Mail = ({ item }) => {
	let {
		subject,
		recipients,
		sender,
		opened,
		clicked,
		sortTime,
		body,
		tableName,
	} = item;
	if (tableName != "receivedMails") sender = "tiemo@eitje.app";
	return (
		<div className="mail">
			<h5> subject: {subject} </h5>
			<Label tableName={tableName} />
			<h5>{formatTime(sortTime())}</h5>
			<h5> from: {sender} </h5>
			<h5> to: {recipients} </h5>
			<p className="mail-body"> {body} </p>
			{opened && <p> {opened} </p>}
			{clicked && <p> {clicked} </p>}
		</div>
	);
};

const Label = ({ tableName }) => {
	const labelName = tableName.replace("Mails", "");
	return <h5 className={`label ${labelName}`}>{labelName}</h5>;
};

export default Mails;
