import React, { useEffect, useContext } from "react";
import { Route, Navigate, Outlet, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { API } from "@eitje/easy_api";
import { useForm, ContextForm } from "@eitje/form";
import { DropdownPicker, Input } from "@eitje/form-fields-web";
import _ from "lodash";
import SimpleList from "components/simple_list";
import { useBoolState } from "@eitje/react-hooks";
import { Button } from "components/common";
import { FormSubmitButton } from "components/form";
import moment from "moment";
import { Contact, ReceivedMail } from "models/index";
import useShow from "hooks/use_show";
import { useContactMails, fetch } from "./helpers";
import ContactHeader from "./contact_header";
import Mails from "./mails";
import ContactActions from "./contact_actions/index";
import MapHeader from "./map_header";
import useGet from "hooks/use_get";
import "./styles/index.less";

const InboxContext = React.createContext({});
const InboxProvider = InboxContext.Provider;

const ContactMails = () => {
	const { contactId, receivedMailId } = useParams();
	const { data } = useGet(`contacts/${contactId}/available_follow_ups`, {
		watch: contactId,
	});

	const { item } = useShow("contacts", { idField: "contactId" });
	const mail = ReceivedMail.find(receivedMailId);

	const mails = useContactMails(contactId);

	if (mail.contact_id != contactId) {
		return <Navigate to="/app/inbox" />;
	}

	return (
		<div className="contact-inbox">
			<InboxProvider
				value={{
					contact: item,
					followUpIds: data.ids || [],
					receivedMail: mail,
				}}
			>
				<ContactHeader item={item} />
				<MapHeader mail={mail} />
				<Mails mails={mails} />
				{item.id && item.approachable() && (
					<ContactActions contact={item} mail={mail} />
				)}
			</InboxProvider>
		</div>
	);
};

export const useInboxContext = () => {
	return useContext(InboxContext);
};

export default ContactMails;
