import _ from "lodash";

const initialState = {
	outreach: {},
	campaign: {},
};

export default function reduce(state = initialState, action) {
	switch (action.type) {
		case "SET_AGGREGATES":
			return {
				...state,
				...action.aggregates,
			};

		default:
			return state;
	}
}
