import React, { Fragment, useEffect } from "react";
import {
	Route,
	Navigate,
	Outlet,
	useParams,
	useNavigate,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { API } from "@eitje/easy_api";
import { useForm, ContextForm } from "@eitje/form";
import { DropdownPicker, Input } from "@eitje/form-fields-web";
import _ from "lodash";
import SimpleList from "components/simple_list";
import { useBoolState } from "@eitje/react-hooks";
import { FormSubmitButton } from "components/form";
import moment from "moment";
import {
	Contact,
	ReceivedMail,
	FollowUp,
	PlannedMail,
	SentMail,
	Campaign,
	Outreach,
} from "models/index";
import useShow from "hooks/use_show";
import { ModalLink, NavLink, Link } from "components/routing";
import { Button, ConfirmButton } from "components/common";
import Modal from "components/modal";
import utils from "@eitje/web_utils";
import { CampaignForm } from "cores/campaigns/index";

const New = () => {
	const labels = _.uniq(FollowUp.all().map((i) => i.label)).filter(Boolean);
	const navigate = useNavigate();
	return (
		<div>
			<CampaignForm
				afterSubmit={({ item }) => navigate(`/app/follow_ups/${item.id}`)}
				onSubmit={(data) => FollowUp.create(data)}
				name={"{{FIRST_NAME}}"}
				type="follow_up"
				labels={labels}
			/>
		</div>
	);
};

export default New;
