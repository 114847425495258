import ActiveRecord from "models/active_record/base";
import {
  API,
  useWhere,
  useFind,
  useAll,
  useSelector as doSelector,
} from "@eitje/easy_api";
import utils from "@eitje/web_utils";

class ReceivedMail extends ActiveRecord {
  static tableName = "receivedMails";
  static defaultJoins = ["contacts"];

  constructor(props) {
    super(props);
    this.sortTime = this.sortTime.bind(this);
  }

  sortTime() {
    return this.created_at;
  }

  remap(contactId) {
    return this.resourceReq("remap", { contact_id: contactId });
  }
}

export default ReceivedMail;
