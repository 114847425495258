import React, { Fragment, useEffect } from "react";
import { Route, Navigate, Outlet, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { API } from "@eitje/easy_api";
import { useForm, ContextForm } from "@eitje/form";
import { DropdownPicker, Input } from "@eitje/form-fields-web";
import _ from "lodash";
import SimpleList from "components/simple_list";
import { useBoolState } from "@eitje/react-hooks";
import { FormSubmitButton } from "components/form";
import moment from "moment";
import {
	Contact,
	ReceivedMail,
	FollowUp,
	PlannedMail,
	SentMail,
} from "models/index";
import useShow from "hooks/use_show";
import { ModalLink, NavLink, Link } from "components/routing";
import { Button, ConfirmButton } from "components/common";
import Modal from "components/modal";
import utils from "@eitje/web_utils";
import { getAggregates } from "cores/campaigns/index";
import "./styles/action_buttons.less";

const Hello = () => {
	return (
		<div className="action-buttons">
			<Button onClick={() => API.arbitrary("outreaches", "checklist_to_slack")}>
				Checklist to Notion
			</Button>
			<ConfirmButton
				afterConfirm={getAggregates}
				onClick={() => API.arbitrary("outreaches", "resume_all")}
			>
				resume all
			</ConfirmButton>
			<ConfirmButton
				afterConfirm={getAggregates}
				onClick={() => API.arbitrary("outreaches", "pause_all")}
			>
				pause all
			</ConfirmButton>
			<Link to="new">
				<Button> create outreach </Button>
			</Link>
		</div>
	);
};

export default Hello;
