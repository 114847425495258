import { setup, initApi } from "@eitje/easy_api";
import { store } from "index";
import { t } from "initializers/i18n";
import { url } from "environment";
import { errNotif } from "utils/ui";
import { refreshTokenIfNeeded, logout } from "actions/auth";
import Reactotron from "reactotron-react-js";
import models from "models/index";
import Association from "models/active_record/association";
import ApplicationRecord from "models/active_record/base";
import utils from "@eitje/web_utils";
import _ from "lodash";
let backend;

const init = () => {
  setup({
    t,
    store,
    baseURL: url,
    alert: errNotif,
    getRefreshToken: refreshTokenIfNeeded,
    models,
    ApplicationRecord,
    Association,
    logout,
    enrichRecords,
    success: utils.success,
  });

  backend = initApi();
  backend.addRequestTransform(increaseLoadCount);
  backend.addMonitor(Reactotron.apisauce);
  backend.addMonitor(decreaseLoadCount);
};

let loadCount = 0;

const increaseLoadCount = () => {
  if (loadCount == 0) {
    store.dispatch({ type: "START_LOADING" });
  }
  loadCount += 1;
};
const decreaseLoadCount = () => {
  if (loadCount == 1) {
    store.dispatch({ type: "STOP_LOADING" });
  }

  loadCount -= 1;
};

const enrichRecords = (ents, key) => {
  const state = store.getState();
  const records = ents[key];
  if (!records) return null;
  switch (key) {
    case "outreaches":
      return mergeAggs(records, state.aggregates?.outreach);
    case "followUps":
      return mergeAggs(records, state.aggregates?.follow_up);
  }
};

const mergeAggs = (items = [], aggs = []) => {
  if (!_.isArray(aggs)) return items;
  if (!items) return;

  return items.map((i) => ({
    ...aggs.find((i2) => i2.id == i.id),
    ...i,
  }));
};

export { init, backend };
