import React, { Fragment, useEffect } from "react";
import { Route, Navigate, Outlet, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { API } from "@eitje/easy_api";
import { useForm, ContextForm } from "@eitje/form";
import { DropdownPicker, Input } from "@eitje/form-fields-web";
import _ from "lodash";
import SimpleList from "components/simple_list";
import { useBoolState } from "@eitje/react-hooks";
import { FormSubmitButton } from "components/form";
import moment from "moment";
import {
	Contact,
	ReceivedMail,
	FollowUp,
	PlannedMail,
	SentMail,
	Snippet,
} from "models/index";
import useShow from "hooks/use_show";
import { ModalLink, NavLink, Link } from "components/routing";
import { Button, ConfirmButton } from "components/common";
import Modal from "components/modal";
import utils from "@eitje/web_utils";
import SimpleGroupedList from "components/simple_grouped_list";
import { copy } from "utils/utils";
import "./styles/snippets.less";

const Snippets = () => {
	const snippets = Snippet.where({ in_use: true });
	return (
		<div className="snippets">
			<h4 className="grouped-list-title"> Snippets </h4>
			<SimpleGroupedList items={snippets} Item={SnippetItem} />
		</div>
	);
};

const SnippetItem = ({ item }) => {
	const { body, name } = item;
	const click = (e) => {
		e.stopPropagation();
		copy(body);
	};
	return <p onClick={click}> {name} </p>;
};

export default Snippets;
