import { Button, Modal as AntModal } from "antd";
import { history } from "index";
import { t } from "initializers/i18n";
import React from "react";
import _ from "lodash";
import { useNavigate } from "react-router-dom";

const ModalContext = React.createContext({});
const ModalProvider = ModalContext.Provider;

const modalSizes = {
  sm: 520,
  md: 750,
  lg: 800,
  xl: 1200,
  fs: undefined,
};

const Modal = (props) => {
  let {
    onOk,
    size = "sm",
    hideOk,
    okText = t("submit"),
    hideClose,
    hasCancel,
    footerBackButton,
    insertAtIndex = {},
    hideDel,
    children,
    noStyle,
    form,
    width,
    redirectIf,
    onDel,
    destructive,
    onCancel,
    afterOk = _.noop,
  } = props;
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  if (!onCancel) onCancel = goBack;

  let condOpts = {};
  if (!onOk && !footerBackButton && !hasCancel) condOpts["footer"] = null;

  const _onDel = async () => {
    form && form.current && form.current.unTouch();
    await onDel();
    goBack();
  };

  const backButton = <Button onClick={history.goBack}>{t("back")}</Button>;

  const _onOk = () => {
    onOk();
    afterOk();
  };

  let footerItems = [
    footerBackButton && backButton,
    !hideDel && onDel && (
      <Button className="eitje-delete-button" danger onDel={_onDel}>
        {t("delete")}
      </Button>
    ),
    hasCancel && (
      <Button className="eitje-cancel-button" key="back" onClick={onCancel}>
        {t("cancel")}
      </Button>
    ),
    !hideOk && onOk && (
      <Button
        className="eitje-submit-button"
        key="submit"
        type="primary"
        onClick={_onOk}
      >
        {okText}
      </Button>
    ),
  ].filter(Boolean);

  Object.keys(insertAtIndex).forEach((idx) => {
    const comp = insertAtIndex[idx];
    footerItems.splice(idx, 0, comp);
  });

  const footer = <div className="eitje-modal-footer"> {footerItems} </div>;

  // Footer back button is defaulting to true therefore footer is only when the array is greater than 1
  let className = [
    size === "fs" && "modal-fullscreen",
    footerItems.length > 0 && "footer",
    hideClose && "hide-close",
    footerBackButton && "left-footer",
  ]
    .filter(Boolean)
    .join(" ");

  return (
    <ModalProvider value={{ goBack }}>
      <AntModal
        className={className}
        footer={footer}
        {...condOpts}
        visible
        onCancel={onCancel}
        {...props}
        width={width || modalSizes[size]}
      ></AntModal>
    </ModalProvider>
  );
};

export default Modal;

const confirm = ({
  okText = t("ok"),
  content,
  title = t("confirm"),
  closable = true,
  cancelText = t("cancel"),
  ...rest
}) =>
  AntModal.confirm({
    okText,
    cancelText,
    title,
    content,
    closable,
    ...rest,
  });

export { ModalContext, ModalProvider, confirm };
