import { useRef } from "react";
import utils from "@eitje/utils";
import { Button, Input } from "antd";
import _ from "lodash";
import { Fragment, useState } from "react";

export const filterItems = (
  items,
  query,
  { searchField = "name", filterFunc, showSearch = true }
) => {
  if (!showSearch || !utils.exists(query)) return items;
  if (filterFunc) return filterFunc(items, query);
  return utils.search(items, query, { field: searchField });
};
const SearchIcon = () => {
  return <img src="/images/search.png" height={18} />;
};
const NewSearchInput = (props) => (
  <Input {...props} prefix={<SearchIcon />} bordered={false} id="" />
);

const useSearch = (items, props = {}) => {
  const { search, setSearch, searchInput } = useSearchInput(props);
  const filteredItems = filterItems(items, search, props);
  return { filteredItems, search, setSearch, searchInput };
};
export default useSearch;
export const useSearchInput = ({
  inputProps,
  newInput,
  placeholder = "Type to search",
  defaultSearch,
  className,
  ...rest
} = {}) => {
  const [search, setSearch] = useState(defaultSearch);
  const inputClassName = utils.makeCns("search_input", className);
  const searchInputRef = useRef(null);
  const searchInputProps = {
    placeholder,
    value: search,
    autocomplete: "off",
    className: inputClassName,
    onChange: (e) => setSearch(e.target.value),
    ...inputProps,
  };
  const SearchInput = newInput ? NewSearchInput : Input;
  const searchInput = (
    <div className="search-container">
      <SearchInput ref={searchInputRef} id={"search"} {...searchInputProps} />
    </div>
  );
  return { search, setSearch, searchInput, searchInputRef };
};
export const SearchEmpty = ({ action = _.noop }) => {
  return (
    <div className="empty-search-container">
      <div className="empty-search-content">
        <img src="/images/noContent.png" width={280} />
        <h2>No results</h2>
        <p className="eitje-text-secondary margin-bottom-xl">
          No search results
        </p>
        <Button onClick={action} type="primary">
          Clear search
        </Button>
      </div>
    </div>
  );
};
