if (!Array.prototype.flat) {
  Object.defineProperty(Array.prototype, "flat", {
    value: function (depth = 1) {
      return this.reduce(function (flat, toFlatten) {
        return flat.concat(
          Array.isArray(toFlatten) && depth > 1
            ? toFlatten.flat(depth - 1)
            : toFlatten
        );
      }, []);
    },
  });
}

Array.prototype.uniq = function () {
  var a = [];
  for (var i = 0, l = this.length; i < l; i++)
    if (a.indexOf(this[i]) === -1) a.push(this[i]);
  return a;
};
