import React, { useEffect } from "react";
import { Route, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { API } from "@eitje/easy_api";
import { useForm } from "@eitje/form";
import { Contact } from "models/index";
import utils from "@eitje/web_utils";
import TableComponent from "components/table";
import { fields } from "./index";
import { navigateModal } from "actions/routing";
import { ModalLink } from "components/routing";
import "./styles/table.less";

const onClick = (item) => {
	navigateModal(`/app/contacts/${item.id}`);
};

const rowProps = {
	onClick,
};

const Table = () => {
	const { getData } = useForm();
	const { filter_query, filter_column } = getData();
	let contacts = Contact.all();
	if (filter_query)
		contacts = utils.search(contacts, `${filter_query}`, {
			field: filter_column,
		});
	return (
		<div className="crm-table">
			<h3 className="crm-table-contacts-count"> {contacts.length} Contacts </h3>
			<TableComponent
				virtual
				rowProps={rowProps}
				fields={fields}
				items={contacts}
			/>
		</div>
	);
};

export default Table;
