import React, { Fragment, useEffect, useState } from "react";
import { Route, Navigate, Outlet, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { API, useGet } from "@eitje/easy_api";
import { useForm, ContextForm } from "@eitje/form";
import { DropdownPicker, Input } from "@eitje/form-fields-web";
import _ from "lodash";
import SimpleList from "components/simple_list";
import { useBoolState } from "@eitje/react-hooks";
import { FormSubmitButton } from "components/form";
import moment from "moment";
import {
  Contact,
  ReceivedMail,
  FollowUp,
  PlannedMail,
  SentMail,
  Campaign,
  Outreach,
} from "models/index";
import useShow from "hooks/use_show";
import { ModalLink, NavLink, Link, LinkBtn } from "components/routing";
import { Button, ConfirmButton } from "components/common";
import Modal from "components/modal";
import utils from "@eitje/web_utils";
import {
  VictoryPie,
  VictoryChart,
  VictoryZoomContainer,
  VictoryLine,
  VictoryTheme,
} from "victory";
import { transformData } from "cores/data/pages/index";

const LineChart = ({ type }) => {
  const { data } = useGet(`data/${type}`);
  const transformed = transformData(data);
  return (
    <div>
      <VictoryChart
        width={1600}
        height={800}
        containerComponent={<VictoryZoomContainer />}
        theme={VictoryTheme.material}
      >
        <VictoryLine data={transformed} />
      </VictoryChart>
    </div>
  );
};

export default LineChart;
