import React, { useEffect, Fragment } from "react";
import { Route, Navigate, Outlet } from "react-router-dom";
import { checkAuth } from "actions/auth";
import { useSelector } from "react-redux";
import { API } from "@eitje/easy_api";
import Header from "components/header";
import { logout } from "actions/auth";
import { Button } from "antd";
import { getTaxonomy } from "actions/general";
import "./styles/body.less";

export function PrivateOutlet() {
  const auth = useSelector((state) => state.auth.refresh_token);
  useInitApp();
  return auth ? <AuthOutlet /> : <Navigate to="/auth" />;
}

const AuthOutlet = () => {
  return (
    <Fragment>
      <Header />

      <div className="body">
        <Outlet />
      </div>
      <Button
        className="button-logout poached-secondary-button"
        onClick={logout}
      >
        logout
      </Button>
    </Fragment>
  );
};

export function PublicOutlet() {
  const auth = useSelector((state) => state.auth.refresh_token);
  return !auth ? <Outlet /> : <Navigate to="/app" />;
}

const useInitApp = () => {
  useEffect(getRecords, []);
};

const getRecords = () => {
  if (process.env.REACT_APP_STYLE == "withContacts") {
    API.index("contacts");
  }
  API.index("received_mails", { filters: { preview_index: true } });
  API.index("sent_mails");
  API.index("outreaches");
  API.index("follow_ups");
  API.index("campaigns");
  API.index("planned_mails");
  API.index("snippets");
  getTaxonomy();
};
