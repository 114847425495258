import React, { useEffect, useContext, useState } from "react";
import { Route, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { API } from "@eitje/easy_api";
import StatusSelect from "./status_select";
import ActionButtons from "./action_buttons";
import Table from "./table";
import { getAggregates } from "cores/campaigns/index";
import { Outreach } from "models/index";
import "./styles/index.less";

const OutreachContext = React.createContext({});
const Provider = OutreachContext.Provider;

const Index = () => {
	const [statusFilter, setStatusFilter] = useState();
	useEffect(getAggregates, []);
	let outreaches = Outreach.index();
	if (statusFilter)
		outreaches = outreaches.filter((o) => o.status == statusFilter);
	return (
		<div>
			<Provider value={{ statusFilter, setStatusFilter }}>
				<StatusSelect />
				<ActionButtons />
				<Table items={outreaches} />
			</Provider>
		</div>
	);
};

export const useOutreachContext = () => {
	return useContext(OutreachContext);
};

export default Index;
