import ActiveRecord from "models/active_record/base";
import { useWhere as doWhere, useFind, useAll } from "@eitje/easy_api";
import { useSelector as doSelector } from "hooks/use_selector";

class Campaign extends ActiveRecord {
  static tableName = "campaigns";

  static merged(query) {
    const aggregates = doSelector((state) => state.aggregates);
    const aggs = aggregates["campaign"];
    const items = this.where(query);
    if (!aggs) return this;
    return items.map((i) => ({
      ...i,
      ...aggs.find((i2) => i2.id == i.id),
    }));
  }
}

export default Campaign;
