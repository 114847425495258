import React, { Fragment, useEffect } from "react";
import { Route, Navigate, Outlet, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { API } from "@eitje/easy_api";
import { useForm, ContextForm } from "@eitje/form";
import { DropdownPicker, Input } from "@eitje/form-fields-web";
import _ from "lodash";
import SimpleList from "components/simple_list";
import { useBoolState } from "@eitje/react-hooks";
import { FormSubmitButton } from "components/form";
import moment from "moment";
import {
	Contact,
	ReceivedMail,
	FollowUp,
	PlannedMail,
	SentMail,
	Campaign,
} from "models/index";
import useShow from "hooks/use_show";
import { ModalLink, NavLink, Link, LinkBtn } from "components/routing";
import { Button, ConfirmButton } from "components/common";
import Modal from "components/modal";
import utils from "@eitje/web_utils";
import Table from "components/table";
import "./styles/table.less";

const fields = [
	"name",
	"link",
	"status",
	"contacts",
	"sent",
	"delivered",
	"opened",
	"clicked",
	"failed",
	"planned",
];

const OutreachTable = ({ items }) => {
	console.log(items);
	return (
		<div>
			<Table
				hideHeaders={["link"]}
				className="sequence-table"
				NestedElement={CampaignTable}
				renderCell={renderCell}
				items={items}
				fields={fields}
			/>
		</div>
	);
};

const CampaignTable = ({ item }) => {
	const { originalItem } = item;
	const items = _.orderBy(Campaign.merged(originalItem.campaign_ids), "id");

	if (items.length == 0) return <p> no campaigns </p>;
	return (
		<Table
			showHeaders={false}
			className="campaign-table"
			items={items}
			fields={fields}
			renderCell={renderCampaignCell}
		/>
	);
};

const renderCampaignCell = (item, field, name) => {
	const val = _.get(item, field);
	switch (field) {
		case "name":
			return `${moment(item.created_at).format("DD-MM-YY")} (#${item.id})`;
		default:
			return val;
	}
};

const renderCell = (item, field, name) => {
	const val = _.get(item, field);
	switch (field) {
		case "link":
			return <LinkBtn to={item.id}> Show </LinkBtn>;
		case "status":
			return <p className={`status-${val}`}> {val} </p>;
		default:
			return val;
	}
};

export default OutreachTable;
