import Contact from "./contact";
import ReceivedMail from "./received_mail";
import SentMail from "./sent_mail";
import PlannedMail from "./planned_mail";
import FollowUp from "./follow_up";
import Snippet from "./snippet";
import Outreach from "./outreach";
import Campaign from "./campaign";
import InternalBlacklist from "./internal_blacklist";

const modelArray = [
	Contact,
	ReceivedMail,
	FollowUp,
	SentMail,
	Snippet,
	PlannedMail,
	Outreach,
	Campaign,
	InternalBlacklist,
];

let models = {};
modelArray.forEach((m) => (models[m.tableName] = m));

modelArray.forEach((m) => (window[m.name] = m));

export default models;

export {
	Contact,
	ReceivedMail,
	SentMail,
	FollowUp,
	Snippet,
	PlannedMail,
	Outreach,
	Campaign,
	InternalBlacklist,
};
