import React, { Fragment, useEffect } from "react";
import {
	Route,
	Navigate,
	Outlet,
	useParams,
	useNavigate,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { API } from "@eitje/easy_api";
import { useForm, ContextForm } from "@eitje/form";
import { DropdownPicker, Input } from "@eitje/form-fields-web";
import _ from "lodash";
import SimpleList from "components/simple_list";
import { useBoolState } from "@eitje/react-hooks";
import { FormSubmitButton } from "components/form";
import moment from "moment";
import {
	Contact,
	ReceivedMail,
	FollowUp,
	PlannedMail,
	SentMail,
	Campaign,
	Outreach,
} from "models/index";
import useShow from "hooks/use_show";
import { ModalLink, NavLink, Link } from "components/routing";
import { Button, ConfirmButton } from "components/common";
import Modal from "components/modal";
import utils from "@eitje/web_utils";
import SimpleGroupedList from "components/simple_grouped_list";
import Table from "components/table";
import "./styles/tables.less";
import { getFollowUpAggregates } from "cores/campaigns/index";

const fields = [
	"name",
	"status",
	"contacts",
	"sent",
	"delivered",
	"opened",
	"clicked",
	"failed",
	"planned",
];

const Tables = () => {
	useEffect(getFollowUpAggregates, []);

	const followUps = FollowUp.index();
	console.log(followUps[0]);
	return (
		<div className="follow-up-tables-list">
			<SimpleGroupedList items={followUps} Group={FollowUpGroup} />
		</div>
	);
};

const FollowUpGroup = ({ items, label }) => {
	const navigate = useNavigate();

	return (
		<div className="follow-up-group">
			<h3 className="follow-up-group-title"> {label} </h3>
			<Table
				rowProps={{ RowWrapper: MyLink }}
				renderCell={renderCell}
				items={items}
				fields={fields}
			/>
		</div>
	);
};

const MyLink = ({ item, children, className }) => {
	return (
		<Link className={className} to={item.id}>
			{children}
		</Link>
	);
};

const renderCell = (item, field, name) => {
	const val = _.get(item, field);
	const { template_mail_count } = item;
	switch (field) {
		case "name":
			return (
				<b>
					{val} ({template_mail_count})
				</b>
			);
		default:
			return val;
	}
};
export default Tables;
