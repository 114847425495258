import React, {
  useContext,
  useEffect,
  Fragment,
  useRef,
  useState,
} from "react";
import utils from "@eitje/web_utils";
import { API, useWhere, useFind, useAll } from "@eitje/easy_api";

const useArrObjState = ({ initialValue = [], idenField = "id" } = {}) => {
  const [state, setState] = useState(initialValue);
  const updateState = (newItem) => {
    const newItems = utils.alwaysArray(newItem);
    const newState = utils.findAndReplace({
      oldItems: state,
      newItems,
      oldIden: idenField,
      newIden: idenField,
    });
    setState(newState);
  };

  const removeItem = (id) => {
    setState((state) => state.filter((i) => i[idenField] != id));
  };

  return [state, updateState, { setState, removeItem }];
};

export default useArrObjState;
