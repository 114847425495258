import utils from "@eitje/utils";
import { Popover } from "antd";
import useSort from "hooks/use_sort";
import _ from "lodash";
import moment from "moment";
import React, { Fragment, useRef, useState, useMemo } from "react";
import { Virtuoso } from "react-virtuoso";
import { useBoolState } from "@eitje/react-hooks";
import "./styles/table.less";

const likeDate = (val) => {
  if (!_.isString(val)) return false;
  const mmt = moment(val, ["YYYY-MM-DD", "YYYY-MM-DDThh:mm:ss.SSSZ"], true);
  return mmt.isValid();
};

const renderDefaultCell = (item, field) => {
  const val = _.get(item, field); // we might need nested access
  if (val && likeDate(val)) {
    return moment(val).format();
  }
  return val;
};

const disallowedChildClasses = ["ant-btn", "actionBTN", "ant-popover"]; // bit hacky, idea is to prevent certrain children from triggering tr's click
// without having to explicitly set stopPropagation in the component. For now only use-case is a popover
// that's why it makes sense to use the offsetParent.

const hasClass = (e) =>
  disallowedChildClasses.some((c) => e.classList.contains(c));

const Row = ({
  item,
  content = () => {},
  columnProps = {},
  onClick = () => {},
  title = () => {},
  fields,
  style,
  placement,
  popoverClassname,
  showActions,
  NestedElement,
  RowWrapper = "div",
}) => {
  const [expanded, toggleExpanded] = useBoolState();
  const sharedProps = {};
  const [mouseIn, setMouseIn] = useState(false);
  const handleMouseMove = () => setMouseIn((s) => !s);
  if (placement) sharedProps["placement"] = placement;
  const popover = useRef(null);
  const { originalItem } = item;
  let _onClick = NestedElement ? toggleExpanded : () => onClick(originalItem);

  return (
    <RowWrapper item={originalItem} className="table-row">
      <tr onClick={_onClick} style={style}>
        {fields.map((f, idx) => {
          const cProps = utils.funcOrObj(columnProps, idx);
          const Component = idx === 0 ? "th" : "td";
          const className =
            idx === 0 ? "table-cell table-col-fix-l" : "table-cell";
          return (
            <Component className={className} {...cProps}>
              {" "}
              {item[f]?.label}{" "}
            </Component>
          );
        })}
        {showActions && (
          <Popover
            destroyTooltipOnHide
            overlayClassName={popoverClassname} // `remove-pop-padding` was here
            ref={popover}
            id="popover"
            content={() => content(originalItem, { popover: popover.current })}
            title={() => title(originalItem)}
            {...sharedProps}
          >
            <td
              onMouseOver={() => handleMouseMove()}
              onMouseOut={() => handleMouseMove()}
              className="actionBTN action-td"
            >
              <img
                className=""
                src={
                  mouseIn
                    ? "/images/icons/dots.png"
                    : "/images/icons/dotsInactive.png"
                }
              />
            </td>
          </Popover>
        )}
      </tr>
      {expanded && <NestedElement item={item} />}
    </RowWrapper>
  );
};

const renderHeader = (f, up, active) => {
  return (
    <Fragment>
      {f}
      {active ? (
        up ? (
          <img src="/images/icons/ascending.png" style={{ width: 12 }} />
        ) : (
          <img src="/images/icons/descending.png" style={{ width: 12 }} />
        )
      ) : (
        <img src="/images/icons/noOrder.png" style={{ width: 12 }} />
      )}
    </Fragment>
  );
};

const Headers = ({
  fields,
  hideHeaders,
  showActions,
  sortField,
  onClick,
  up,
}) => {
  return (
    <thead>
      <tr>
        {fields.map((f) => (
          <th className="headers" onClick={() => onClick(f)}>
            {" "}
            {hideHeaders.includes(f)
              ? ""
              : renderHeader(f, up, sortField === f)}{" "}
          </th>
        ))}
        {showActions && <th className="action-td">Actions</th>}
      </tr>
    </thead>
  );
};

const Table = (props) => {
  const {
    items = [],
    fields = [],
    actionMenu,
    renderEmpty,
    virtual,
    emptyPlaceholder = null,
    actionTitle,
    rowProps = {},
    style = {},
    showCount,
    hideHeaders = [],
    showEmpty = [],
    showHeaders = true,
    NestedElement,
    className,
    renderCell = () => null,
  } = props;

  const makeCell = (item, field, idx) => {
    const val = item[field];
    if (!val && !showEmpty.includes(field) && item.hasOwnProperty(field))
      return { label: emptyPlaceholder, value: val, field };
    const cellData =
      renderCell(item, field, idx) || renderDefaultCell(item, field, idx);
    if (_.has(cellData, "value")) return cellData;
    return { label: cellData, value: _.get(item, field) };
  };

  const tableItems = useMemo(
    () =>
      items.map((i) => {
        let obj = {};
        fields.forEach((f) => (obj[f] = makeCell(i, f)));
        return { ...obj, originalItem: i };
      }),
    [items.length, items[0]?.id]
  );

  const { changeSort, asc, sortedItems, sortField } = useSort({
    ...props,
    items: tableItems,
    nested: true,
  });

  const { showActions } = rowProps;

  const Wrapper = virtual ? Virtuoso : FakeVirtuoso;

  return (
    <div className={utils.makeCns("table-container", className)} style={style}>
      <table>
        {showCount && `(${items.length})`}

        {showHeaders && (
          <Headers
            showActions={showActions}
            up={asc}
            sortField={sortField}
            onClick={changeSort}
            fields={fields}
            hideHeaders={hideHeaders}
          />
        )}

        <tbody>
          <Wrapper
            className="virtuoso"
            style={{ height: "calc(100vh - 190px)" }}
            useWindowScroll
            data={sortedItems}
            itemContent={(index, i) => {
              const rProps = utils.funcOrObj(rowProps, i);
              return (
                <Row
                  title={actionTitle}
                  content={actionMenu}
                  item={i}
                  fields={fields}
                  {...rProps}
                  NestedElement={NestedElement}
                />
              );
            }}
          />
        </tbody>
      </table>
      {items.length === 0 && renderEmpty}
    </div>
  );
};

const FakeVirtuoso = ({ data, itemContent }) =>
  data.map((item, idx) => itemContent(idx, item));

export default Table;
export { Table };
