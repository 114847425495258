import React, { Fragment } from "react";
import Login from "pages/auth/login";
import NotFound from "pages/system/not_found";
import ErrorBoundary from "components/error_boundary";
import { logout } from "actions/auth";
import { Button } from "antd";
import { Route, Routes, Navigate, Outlet, useLocation } from "react-router-dom";
import { PrivateOutlet, PublicOutlet } from "components/routes";
import Contacts from "cores/contacts/pages/index/index";

import { Inbox, ContactMails } from "cores/mail/inbox/index";
import AutoMail from "cores/mail/auto_mail/index";

import UnmappedMails from "cores/mail/inbox/pages/index/contact_mails/unmapped_mail";

import Outreach from "cores/campaigns/outreach/pages/index/index";
import NewOutreach from "cores/campaigns/outreach/pages/new/index";
import EditOutreach from "cores/campaigns/outreach/pages/edit/index";

import FollowUps from "cores/campaigns/follow_up/pages/index/index";
import NewFollowUp from "cores/campaigns/follow_up/pages/new/index";
import EditFollowUp from "cores/campaigns/follow_up/pages/edit/index";

import SendFollowUp from "cores/campaigns/follow_up/pages/send";
import Data from "cores/data/pages/index";
import SentMailsData from "cores/data/pages/charts/sent_mails";
import ReceivedMailsData from "cores/data/pages/charts/received_mails";
import PlannedMailsData from "cores/data/pages/charts/planned_mails";
import ContactsByStatus from "cores/data/pages/charts/contacts_by_status";
import CampaignResults from "cores/data/pages/charts/campaign_results";

import BlackList from "cores/settings/pages/blacklist";
import SpamList from "cores/settings/pages/spamlist";
import Snippets from "cores/settings/pages/snippets";
import CleanseContacts from "cores/settings/pages/cleanse_contacts/index";
import { ReceivedMail } from "models/index";

import ContactShow from "cores/contacts/pages/show/index";
import ReMap from "cores/mail/received_mails/re_map/index";
import SendBlank from "cores/contacts/pages/send_blank/index";

import "./styles/app/app.less";

const App = () => {
  const location = useLocation();
  const background = location.state && location.state.background;
  const firstContactId = ReceivedMail.all().last?.contact_id;
  return (
    <ErrorBoundary>
      <Routes location={background || location}>
        <Route path="/auth" element={<PublicOutlet />}>
          <Route index element={<Login />} />
          <Route path="*" element={<Navigate to="/auth" />} />
        </Route>
        <Route path="/app" element={<PrivateOutlet />}>
          <Route index element={<Inbox />} />
          <Route path="inbox" element={<Inbox />}>
            <Route path=":receivedMailId" element={<UnmappedMails />} />
            <Route
              path=":receivedMailId/:contactId"
              element={<ContactMails />}
            />
          </Route>

          <Route path="contacts" element={<Contacts />}></Route>
          <Route
            path="contacts/:id/received_mails/:received_mail_id"
            element={<Outlet />}
          >
            <Route path="follow_up/:follow_up_id" element={<SendFollowUp />} />
            <Route path="send_blank" element={<SendBlank />} />
          </Route>

          <Route path="outreaches" element={<Outlet />}>
            <Route index element={<Outreach />} />
            <Route path="new" element={<NewOutreach />} />
            <Route path=":id" element={<EditOutreach />} />
          </Route>

          <Route path="follow_ups" element={<Outlet />}>
            <Route index element={<FollowUps />} />
            <Route path="new" element={<NewFollowUp />} />
            <Route path=":id" element={<EditFollowUp />} />
          </Route>

          <Route path="data" element={<Data />}>
            <Route path="sent_mails" element={<SentMailsData />} />
            <Route path="planned_mails" element={<PlannedMailsData />} />
            <Route path="received_mails" element={<ReceivedMailsData />} />
            <Route path="contacts_by_status" element={<ContactsByStatus />} />
            <Route path="campaign_results" element={<CampaignResults />} />
          </Route>

          <Route path="blacklist" element={<BlackList />} />
          <Route path="spamlist" element={<SpamList />} />
          <Route path="snippets" element={<Snippets />} />
          <Route path="cleanse_contacts" element={<CleanseContacts />} />
          <Route path="auto_mail" element={<AutoMail />} />
        </Route>

        {!background && (
          <Route path="/app/contacts/:id" element={<ContactShow />} />
        )}
        {!background && (
          <Route path="/app/received_mails/:id/map" element={<ReMap />} />
        )}
        <Route path="*" element={<Navigate to="/app/inbox" />} />
      </Routes>

      {background && <BackgroundRoutes />}
    </ErrorBoundary>
  );
};

const BackgroundRoutes = () => {
  return (
    <Routes>
      <Route path="/app/contacts/:id" element={<ContactShow />} />
      <Route path="/app/received_mails/:id/map" element={<ReMap />} />
    </Routes>
  );
};

export default App;

const Home = () => (
  <Fragment>
    <p> What a beautiful project you got there! </p>
    <Button onClick={logout}> logout </Button>
  </Fragment>
);
