import React, { Fragment, useState, useContext, useEffect } from "react";
import { Route, Navigate, Outlet, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { API } from "@eitje/easy_api";
import { useForm, ContextForm } from "@eitje/form";
import { DropdownPicker, Input } from "@eitje/form-fields-web";
import _ from "lodash";
import SimpleList from "components/simple_list";
import { useBoolState, useMergeState } from "@eitje/react-hooks";
import { FormSubmitButton } from "components/form";
import moment from "moment";
import {
	Contact,
	ReceivedMail,
	FollowUp,
	PlannedMail,
	SentMail,
} from "models/index";
import useShow from "hooks/use_show";
import { ModalLink, NavLink, Link } from "components/routing";
import { Button, ConfirmButton } from "components/common";
import Modal from "components/modal";
import utils from "@eitje/web_utils";
import { ContactInfo, Mails, useContactMailsLocal } from "cores/mail/index";
import MailSequenceForm from "./mail_sequence_form";
import SingleMails from "./single_mails";
import Snippets from "./snippets";
import useQueryParams from "hooks/use_query_params";
import { history } from "index";
import "./styles/index.less";

const SendMailsContext = React.createContext({});
const SendMailsProvider = SendMailsContext.Provider;

const SendMails = ({
	hideSnippets,
	kind,
	endPoint,
	initialValues,
	mergeData,
	hideSingleMails,
}) => {
	const [multiForm, setMultiForm] = useState();
	const [templateMail, setTemplateMail] = useState();

	const queryParams = useQueryParams();
	const params = useParams();
	const { email } = queryParams;
	const { item } = useShow("contacts");
	const mails = useContactMailsLocal(item.id);
	const _onSubmit = (data) => {
		let finalData = makeData(data, { ...params, ...queryParams });
		finalData = { ...finalData, ...mergeData };
		return API.resourceReq(kind, endPoint, finalData);
	};

	return (
		<div className="send-page-mixin">
			<SendMailsProvider value={{ setMultiForm, multiForm }}>
				<div className="contact-header">
					<ContactInfo item={item} />
					<h4 className="contact-email">{email}</h4>
				</div>

				<div className="send-page-content">
					<Mails mails={mails} />
					<MailSequenceForm
						afterSubmit={history.back}
						setMultiForm={setMultiForm}
						onSubmit={_onSubmit}
						name={item.first_name}
						initialValues={initialValues}
					/>
					<div className="template-column">
						{!hideSingleMails && <SingleMails />}
						<Snippets />
					</div>
				</div>
			</SendMailsProvider>
		</div>
	);
};

const makeData = (data, params) => {
	return {
		mails: data.map(mapMail),
		received_mail_id: params.received_mail_id,
		email_address: params.email,
	};
};

const mapMail = (mail, idx) => {
	return { ...mail, position: idx + 1 };
};

export const useSendMailsContext = () => {
	return useContext(SendMailsContext);
};
export default SendMails;
