import React from "react";
import { Button } from "antd";
import { login } from "actions/auth";
import { Input } from "@eitje/form-fields-web";
import Form from "initializers/form";
import "./styles/login.less";
import { ContextForm, useForm } from "@eitje/form";
import { FormSubmitButton } from "components/form";

const Login = () => {
  return (
    <div className="login-page">
      <h3>Login</h3>

      <ContextForm onSubmit={(data) => login(data)}>
        <Input required field="email" type="email" />
        <Input required secure field="password" />
        <FormSubmitButton />
      </ContextForm>
    </div>
  );
};

export default Login;
