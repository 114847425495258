import React, { Fragment, useEffect } from "react";
import { Route, Navigate, Outlet, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { API } from "@eitje/easy_api";
import { useForm, ContextForm } from "@eitje/form";
import { DropdownPicker, Input } from "@eitje/form-fields-web";
import _ from "lodash";
import SimpleList from "components/simple_list";
import { useBoolState } from "@eitje/react-hooks";
import { FormSubmitButton } from "components/form";
import moment from "moment";
import {
	Contact,
	ReceivedMail,
	FollowUp,
	PlannedMail,
	SentMail,
	Campaign,
	Outreach,
} from "models/index";
import useShow from "hooks/use_show";
import { ModalLink, NavLink, Link, LinkBtn } from "components/routing";
import { Button, ConfirmButton } from "components/common";
import Modal from "components/modal";
import utils from "@eitje/web_utils";
import CampaignForm from "cores/campaigns/mixins/campaign_form";
import StartOutreach from "./start_outreach";
import "./styles/index.less";

const Edit = () => {
	const { item: outreach, isLoading } = Outreach.show();
	const { status } = outreach;
	const isPaused = status == "paused";
	const isRunning = status == "pending";
	const action = isPaused ? "resume" : "pause";
	const isActionable = isPaused || isRunning;
	if (isLoading) return null;

	return (
		<div>
			<CampaignForm
				initialValues={outreach}
				onSubmit={(data) => Outreach.create(data)}
				name={"{{NAME}}"}
				type="outreach"
			>
				<Input field="subject" disabled />

				{isActionable && (
					<div className="pause-sequence">
						<h4 className="sequence-status-text"> status: {status} </h4>
						<Button
							className="button-pause poached-secondary-button"
							onClick={() => outreach.resourceReq(action)}
						>
							{action}
						</Button>
					</div>
				)}
				<StartOutreach item={outreach} />
			</CampaignForm>
		</div>
	);
};

export default Edit;
