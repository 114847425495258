import React, { Fragment, useEffect } from "react";
import { Route, Navigate, Outlet, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { API } from "@eitje/easy_api";
import { useForm, ContextForm } from "@eitje/form";
import { DropdownPicker, Input } from "@eitje/form-fields-web";
import _ from "lodash";
import SimpleList from "components/simple_list";
import { useBoolState } from "@eitje/react-hooks";
import { FormSubmitButton } from "components/form";
import moment from "moment";
import {
	Contact,
	ReceivedMail,
	FollowUp,
	PlannedMail,
	SentMail,
} from "models/index";
import useShow from "hooks/use_show";
import { ModalLink, NavLink, Link } from "components/routing";
import { Button, ConfirmButton } from "components/common";
import Modal from "components/modal";
import utils from "@eitje/web_utils";
import Mails from "./mails";
import MapHeader from "./map_header";
import { SpamButton } from "cores/mail/index";
import "./styles/unmapped_mails.less";

const UnmappedMails = () => {
	const { contactId, receivedMailId } = useParams();
	const mail = ReceivedMail.find(receivedMailId);

	return (
		<div id="unmapped-mails">
			<Header email={mail.sender} />
			<MapHeader mail={mail} />
			<Mails mails={[mail]} />
		</div>
	);
};

const Header = ({ email }) => {
	return (
		<div className="contact-header unmapped-header">
			<h3 className="contact-name"> {email} </h3>
			<div className="contact-header-buttons">
				<SpamButton email={email} />
			</div>
		</div>
	);
};

export default UnmappedMails;
