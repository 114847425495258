import React, { Fragment, useEffect } from "react";
import { Route, Navigate, Outlet, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { API } from "@eitje/easy_api";
import { useForm, ContextForm } from "@eitje/form";
import { DropdownPicker, Input } from "@eitje/form-fields-web";
import _ from "lodash";
import SimpleList from "components/simple_list";
import { useBoolState } from "@eitje/react-hooks";
import { FormSubmitButton } from "components/form";
import moment from "moment";
import {
	Contact,
	ReceivedMail,
	FollowUp,
	PlannedMail,
	SentMail,
} from "models/index";
import useShow from "hooks/use_show";
import { ModalLink, ModalLinkBtn, NavLink, Link } from "components/routing";
import { Button, ConfirmButton } from "components/common";
import utils from "@eitje/web_utils";
import "./styles/map_header.less";

const MapHeader = ({ contact, mail }) => {
	const { current_map, id } = mail;
	const classNames = utils.makeCns("map-header", `map-header-${current_map}`);
	return (
		<div className={classNames}>
			<p className="map-header-status"> {current_map} </p>
			<ModalLinkBtn
				className="poached-secondary-button"
				text="map"
				to={`/app/received_mails/${id}/map`}
			/>
		</div>
	);
};

export default MapHeader;
