import React, { useEffect } from "react";
import { Route, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { API } from "@eitje/easy_api";
import { Contact } from "models/index";
import { ContextForm, useForm } from "@eitje/form";
import { DropdownPicker, Input } from "@eitje/form-fields-web";
import "styles/general.css";
import Table from "./table";
import Filter from "./filter";

export const fields = ["name", "outreach_names", "status", "email_addresses"];

const Contacts = () => {
	const contacts = Contact.all();
	return (
		<div>
			<ContextForm initialValues={{ filter_column: "name" }}>
				<Filter />
				<Table />
			</ContextForm>
		</div>
	);
};

export default Contacts;
