import React, { useEffect } from "react";
import {
	Route,
	Navigate,
	Outlet,
	useNavigate,
	useParams,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { API } from "@eitje/easy_api";
import { useForm, ContextForm } from "@eitje/form";
import { DropdownPicker, Input } from "@eitje/form-fields-web";
import _ from "lodash";
import SimpleList from "components/simple_list";
import { useBoolState } from "@eitje/react-hooks";
import { Button } from "components/common";
import { FormSubmitButton } from "components/form";
import moment from "moment";
import { Contact, ReceivedMail } from "models/index";
import { Virtuoso } from "react-virtuoso";
import { NavLink } from "components/routing";
import useSearch from "hooks/use_search";
import { formatTime } from "utils/utils";
import { inboxUrl } from "cores/mail/inbox/helpers";
import "./styles/contact_list.less";
import useHotkey from "hooks/use_hotkey";

const ContactList = () => {
	let mails = _.orderBy(ReceivedMail.all(), "created_at", ["desc"]);
	const { searchInput, filteredItems } = useSearch(mails, {
		searchField: ["sender", "contact.name"],
	});

	const params = useParams();
	const { receivedMailId } = params;

	const navigate = useNavigate();
	const scrollToNext = () => {
		const currentItem = filteredItems.find((i) => i.id == receivedMailId);
		const idx = filteredItems.indexOf(currentItem);
		const item = filteredItems[idx + 1];
		if (item) navigate(inboxUrl(item));
	};

	const scrollToPrev = () => {
		const currentItem = filteredItems.find((i) => i.id == receivedMailId);
		const idx = filteredItems.indexOf(currentItem);
		const item = filteredItems[idx - 1];
		if (item) navigate(inboxUrl(item));
	};

	const onKeyUp = useHotkey({ arrowdown: scrollToNext, arrowup: scrollToPrev });

	return (
		<div onKeyUp={onKeyUp} id="received-mails">
			{searchInput}
			<Virtuoso
				style={{ height: "calc(100vh - 200px)" }}
				data={filteredItems}
				itemContent={(index, i) => <Mail item={i} />}
			/>
		</div>
	);
};

const Mail = ({ item }) => {
	const readMails = useSelector((state) => state.reading.readMails);
	const { id, we_replied, sender, created_at, contact_id } = item;

	const contact = Contact.find(contact_id);
	const name = contact.name || sender;
	return (
		<NavLink to={inboxUrl(item)} className="received-mail">
			<p className="received-mail-contact-name"> {name} </p>
			<span className="received-mail-time"> {formatTime(created_at)} </span>
			{!we_replied && <Circle className="we-replied" />}
		</NavLink>
	);
};

const Circle = ({ className }) => (
	<div className={`color-circle ${className}`} />
);

export default ContactList;
