import { useWhere, API, useFind, useIndex as doIndex } from "@eitje/easy_api";
import utils from "@eitje/web_utils";
import doShow from "hooks/use_show";
import _ from "lodash";

const actionsMixin = (Base) =>
	class extends Base {
		static create(params) {
			return API.create(this.tableName, params);
		}

		static index(params) {
			return doIndex(utils.camelToSnake(this.tableName), params);
		}

		static update = this.create;

		static destroy(id) {
			return API.destroy(this.tableName, id);
		}

		static show(params = {}) {
			if (_.isNumber(params)) params = { id: params };
			return doShow(utils.camelToSnake(this.tableName), params);
		}

		refresh() {
			return API.show(this.tableName, this.id);
		}

		update(params) {
			return API.update(this.tableName, { ...params, id: this.id });
		}

		destroy() {
			return API.destroy(this.snakeTableName(), this.id);
		}

		resourceReq(endpoint, params = {}) {
			return API.resourceReq(utils.camelToSnake(this.tableName), endpoint, {
				...params,
				id: this.id,
			});
		}
	};

export default actionsMixin;
