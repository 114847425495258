import ActiveRecord from "models/active_record/base";
import {
  useWhere,
  useFind,
  useAll,
  useSelector as doSelector,
} from "@eitje/easy_api";

class InternalBlacklist extends ActiveRecord {
  static tableName = "internalBlacklists";
}

export default InternalBlacklist;
