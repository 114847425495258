import React, { Fragment, useEffect, useState } from "react";
import { Route, Navigate, Outlet, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { API, useGet } from "@eitje/easy_api";
import { useForm, ContextForm } from "@eitje/form";
import { DropdownPicker, Input } from "@eitje/form-fields-web";
import _ from "lodash";
import SimpleList from "components/simple_list";
import { useBoolState } from "@eitje/react-hooks";
import { FormSubmitButton } from "components/form";
import moment from "moment";
import {
  Contact,
  ReceivedMail,
  FollowUp,
  PlannedMail,
  SentMail,
  Campaign,
  Outreach,
} from "models/index";
import useShow from "hooks/use_show";
import { ModalLink, NavLink, Link, LinkBtn } from "components/routing";
import { Button, ConfirmButton } from "components/common";
import Modal from "components/modal";
import utils from "@eitje/web_utils";
import {
  VictoryPie,
  VictoryChart,
  VictoryGroup,
  VictoryLine,
  VictoryTheme,
  VictoryStack,
  VictoryBar,
  VictoryAxis,
  VictoryLabel,
  VictoryTooltip,
} from "victory";

const makeLabel = ({ datum }) => `${datum.status}: ${Math.round(datum.y)} `;

const Hello = () => {
  let { data = [] } = useGet("data/outreach_success");
  if (!_.isArray(data)) return null;
  const deadData = data.map((d) => ({
    status: "dead",
    x: d.name,
    y: d.dead || 0,
  }));
  const leadData = data.map((d) => ({
    status: "lead",
    x: d.name,
    y: d.lead || 0,
  }));
  const hubspotData = data.map((d) => ({
    status: "hubspot",
    x: d.name,
    y: d.hubspot || 0,
  }));

  return (
    <div>
      <VictoryChart height={800} width={1600}>
        <VictoryGroup offset={20} colorScale={"qualitative"}>
          <VictoryBar
            labelComponent={<VictoryTooltip />}
            labels={makeLabel}
            data={deadData}
          />
          <VictoryBar
            labelComponent={<VictoryTooltip />}
            labels={makeLabel}
            data={leadData}
          />
          <VictoryBar
            labelComponent={<VictoryTooltip />}
            labels={makeLabel}
            data={hubspotData}
          />
        </VictoryGroup>
        <VictoryAxis fixLabelOverlap dependentAxis />
        <VictoryAxis style={{ ticks: { fontSize: 3 } }} />
      </VictoryChart>
    </div>
  );
};

export default Hello;
