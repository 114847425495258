import utils from "@eitje/web_utils";
import { history } from "index";
import { useLocation } from "react-router-dom";
import _ from "lodash";

export const navigateModal = (
  path,
  { search = "", state = {}, replace } = {}
) => {
  let loc = history.location;
  if (_.isObject(search)) {
    search = new URLSearchParams(search).toString();
  }
  if (replace) loc = loc.state.background;
  const args = [`${path}?${search}`, { ...state, background: loc }];
  replace ? history.replace(...args) : history.push(...args);
};

export function scrollToEnd() {
  const el = document.scrollingElement;
  const scrollLeft = el.scrollHeight - el.scrollTop;
  return scrollLeft - el.clientHeight;
}

export function scrollFromTop() {
  const el = document.scrollingElement;
  return el.scrollTop;
}

export function getCurrentRoute() {
  return history?.location?.pathname;
}
