import "./styles/index.less";
import React, { Fragment, useEffect, useState } from "react";
import { Route, Navigate, Outlet, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { API, useFind } from "@eitje/easy_api";
import { useForm, ContextForm } from "@eitje/form";
import { Switch } from "antd";
import { DropdownPicker, BaseInput as Input } from "@eitje/form-fields-web";
import _ from "lodash";
import SimpleList from "components/simple_list";
import {
  useBoolState,
  useToggleState,
  useIncrementState,
  usePrevious,
} from "@eitje/react-hooks";
import { FormSubmitButton } from "components/form";
import moment from "moment";
import {
  Contact,
  ReceivedMail,
  FollowUp,
  PlannedMail,
  SentMail,
  Campaign,
  Outreach,
} from "models/index";
import { Spin } from "antd";
import useShow from "hooks/use_show";
import {
  ModalLink,
  NavLink,
  Link,
  LinkBtn,
  ModalLinkBtn,
} from "components/routing";
import { Button, ConfirmButton } from "components/common";
import Modal from "components/modal";
import utils from "@eitje/web_utils";
import useGet from "hooks/use_get";
import useArrObjState from "hooks/use_arr_obj_state";
import { backend } from "@eitje/easy_api";

const _submit = async (items, ignoredIds, contactsToUpdate) => {
  const approved_ids = items
    .map((i) => i.id)
    .filter((id) => !ignoredIds.includes(id));
  const params = {
    contacts: contactsToUpdate,
    refused_ids: ignoredIds,
    approved_ids,
  };
  const res = await backend.post(`received_mails/approve_auto_replies`, params);
  if (res.ok) {
    window.location.reload();
  }
};

const submit = utils.debounce(_submit, 1000, true);

const Index = () => {
  const [ignoredIds, setIgnoredIds] = useToggleState([]);
  const [contactsToUpdate, setContacts] = useArrObjState();
  const [submitCount, setSubmitCount] = useIncrementState(1);
  const { data = {}, loading } = useGet(`received_mails/check_auto_replies`, {
    watch: submitCount,
  });
  const { items = [] } = data;

  const prevItems = usePrevious(items);
  useEffect(() => {
    if (!utils.exists(prevItems) && utils.exists(items)) {
      setContacts(
        items.map((i) => ({
          id: i.contact_id,
          first_name: i.first_name,
          mail_id: i.id,
        }))
      );
    }
  }, [data]);

  if (loading) return <Spin spinning />;

  const sortedItems = _.orderBy(items, "auto_reply_name");
  return (
    <div className="auto-mail-page">
      <h2 className="page-title"> {items.length} mails to check </h2>
      {sortedItems.map((i) => (
        <AutoMail
          setContacts={setContacts}
          setIgnoredIds={setIgnoredIds}
          ignored={ignoredIds.includes(i.id)}
          item={i}
        />
      ))}

      <ConfirmButton
        onClick={() => submit(items, ignoredIds, contactsToUpdate)}
      >
        Submit all
      </ConfirmButton>
    </div>
  );
};

const AutoMail = ({ item, setIgnoredIds, setContacts, ignored }) => {
  const {
    auto_reply_name,
    first_name,
    contact_id,
    id,
    strip_body,
    sequence_history = [],
    auto_reply_patterns,
  } = item;

  const contact = useFind("contacts", contact_id);
  const classNames = utils.makeCns("auto-mail-item", ignored && "ignored");
  return (
    <div className={classNames}>
      <div className="auto-mail-item-left">
        <h2> {auto_reply_name} </h2>
        <p> {contact.name} </p>
        matches:
        <ul>
          {auto_reply_patterns.map((p) => (
            <li> {p} </li>
          ))}
        </ul>
        {utils.exists(sequence_history) && (
          <Fragment>
            sequence history:
            <ul>
              {sequence_history.map((hist) => (
                <li>
                  {hist.name} started {hist.type} on {hist.date}{" "}
                </li>
              ))}
            </ul>
          </Fragment>
        )}
        <Input
          onChange={(val) =>
            setContacts({ id: contact_id, first_name: val, mail_id: id })
          }
          defaultValue={first_name}
        />
        <Switch checked={!ignored} onChange={() => setIgnoredIds(id)} />
        <ModalLinkBtn
          className="info-button"
          to={`/app/contacts/${contact_id}`}
          text="info"
        />
      </div>
      <div className="auto-mail-item-right">
        <p dangerouslySetInnerHTML={{ __html: strip_body }} />
      </div>
    </div>
  );
};

export default Index;
