import _ from "lodash";

const initialState = {
	taxonomy: {},
};

export default function reduce(state = initialState, action) {
	switch (action.type) {
		case "GET_TAXONOMY":
			return {
				...state,
				taxonomy: action.taxonomy,
			};

		default:
			return state;
	}
}
