import React from "react";

const Err = () => (
  <div>
    <div style={{ marginTop: "12%" }}>
      <h2>Oopsie...</h2>
      <p>Something unexpected went wrong</p>
    </div>
  </div>
);

export default Err;
