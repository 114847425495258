import React, { useEffect } from "react";
import { Route, Navigate, useParams, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { API } from "@eitje/easy_api";
import ContactList from "./components/contact_list";
import { Contact, ReceivedMail } from "models/index";
import { inboxUrl } from "cores/mail/inbox/helpers";
import { readMails } from "cores/mail/actions";
import { useInterval } from "@eitje/react-hooks";
import { usePreventWindowKeyDown } from "cores/mail/inbox/index";

const Inbox = () => {
	const { receivedMailId, contactId } = useParams();
	const receivedMail = ReceivedMail.find(receivedMailId);
	const mail = ReceivedMail.all().first();
	usePreventWindowKeyDown();
	useInterval(() => {
		API.index("received_mails", { filters: { preview_index: true } });
	}, 30_000);

	if (receivedMailId && !receivedMail.id) return <Navigate to="/app/inbox" />;
	if (receivedMail.contact_id && !contactId)
		return <Navigate to="/app/inbox" />;
	return (
		<div>
			<ContactList />
			<Outlet />
			{!receivedMailId && mail?.id && <Navigate to={inboxUrl(mail)} />}
		</div>
	);
};

export default Inbox;
