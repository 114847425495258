import React, { useState, useEffect, Fragment } from "react";
import { useAsyncEffect, useIncrementState } from "@eitje/react-hooks";
import { backend } from "@eitje/easy_api";

export const useGet = (
  endPoint,
  { watch = "a", params = {}, shouldFetch = true, skipInitial } = {}
) => {
  const [retries, retry] = useIncrementState();
  const [data, setData] = useState({});
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  useAsyncEffect(async () => {
    if (watch && shouldFetch) {
      setLoading(true);
      const res = await backend.get(endPoint, params);

      if (res.ok) {
        setData(res.data);
      } else {
        setError(true);
      }
      setLoading(false);
    }
  }, [watch]);

  return { data, error, loading, retry };
};

export default useGet;
