import React, { useEffect } from "react";
import { Route, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { API } from "@eitje/easy_api";
import { Link, NavLink } from "components/routing";
import "./styles/index.less";

const routes = [
	"sent_mails",
	"planned_mails",
	"received_mails",
	"contacts_by_status",
	"campaign_results",
];

export const transformData = (data) => {
	const arr = [];
	return Object.keys(data)
		.sort()
		.map((d) => ({ x: new Date(d), y: data[d] }));
};

const Data = () => {
	return (
		<div className="data-page">
			<div className="data-menu">
				{routes.map((r) => (
					<NavLink className="data-header-item" to={r} />
				))}
			</div>
			<div className="data-charts">
				<Outlet />
			</div>
		</div>
	);
};

export default Data;
