import ActiveRecord from "models/active_record/base";
import {
  useWhere,
  useFind,
  useAll,
  useSelector as doSelector,
} from "@eitje/easy_api";

class SentMail extends ActiveRecord {
  static tableName = "sentMails";
  static defaultJoins = ["contacts"];

  constructor(props) {
    super(props);
    this.sortTime = this.sortTime.bind(this);
  }

  sortTime() {
    return this.sent_time;
  }
}

export default SentMail;
