import _ from "lodash";

const initialState = {
	readMails: [],
};

export default function reduce(state = initialState, action) {
	switch (action.type) {
		case "LOGOUT":
			return initialState;
		case "READ_MAILS":
			return {
				...state,
				readMails: _.uniq([...state.readMails, ...action.ids]),
			};

		case "RESET_READ_MAILS":
			return {
				...state,
				readMails: [],
			};

		default:
			return state;
	}
}
