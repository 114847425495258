import React, { useEffect } from "react";
import {
	Route,
	Link as RouterLink,
	NavLink as RouterNavLink,
	Navigate,
	Outlet,
	useLocation,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { API } from "@eitje/easy_api";
import _ from "lodash";
import { Button } from "components/common";

const Link = (props) => {
	let { to, children } = props;
	if (!children && _.isString(to)) children = <p> {to} </p>;
	if (_.isNumber(to)) to = to.toString();
	return <RouterLink {...props} to={to} children={children} />;
};

const NavLink = (props) => {
	let { to, children } = props;
	if (!children) children = <p> {to} </p>;
	if (_.isNumber(to)) to = to.toString();

	return <RouterNavLink {...props} to={to} children={children} />;
};

export const ModalLink = ({ to, keepLoc, hash, state = {}, ...rest }) => {
	// we used to determine the previousLoc based on the keepLoc prop, but it seems to be simpler than that:
	// keep loc if you're already in a modal and don't if you're not
	const loc = useLocation();
	const previousLoc = loc?.state?.background || loc;
	state = { modalRedirect: true, background: previousLoc, ...state };
	return (
		<Link className="modalLink" to={to} state={state} hash={hash} {...rest} />
	);
};

export const ModalLinkBtn = ({ to, keepLoc, text, children, ...rest }) => {
	const childs = text || children;
	return (
		<ModalLink to={to} keepLoc={keepLoc}>
			<Button type="primary" {...rest} children={childs} />
		</ModalLink>
	);
};

export const LinkBtn = ({ to, keepLoc, text, children, ...rest }) => {
	const childs = text || children;
	return (
		<Link to={to}>
			<Button type="primary" {...rest} children={childs} />
		</Link>
	);
};

export { Link, NavLink };
