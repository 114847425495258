import ActiveRecord from "models/active_record/base";
import {
  useWhere,
  useFind,
  useAll,
  useSelector as doSelector,
} from "@eitje/easy_api";
import { ReceivedMail, FollowUp, PlannedMail, SentMail } from "models/index";

class Snippet extends ActiveRecord {
  static tableName = "snippets";
}

export default Snippet;
