import moment from "moment";
import utils from "@eitje/web_utils";

const formatTime = (date) => {
	date = moment(date);
	const time = date.format("HH:mm");
	if (date.isSame(moment(), "day")) {
		return `Today ${time}`;
	}
	if (date.isSame(moment().subtract(1, "day"), "day")) {
		return `Yesterday ${time}`;
	}
	return date.format("DD/MM/YY HH:mm");
};

const copy = async (text) => {
	const copied = await navigator.clipboard.writeText(text);
	utils.success("copied_to_clip");
};

export { formatTime, copy };
