import React, { Fragment, useEffect, useRef } from "react";
import { Route, Navigate, Outlet, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { API, backend } from "@eitje/easy_api";
import { useForm, ContextForm } from "@eitje/form";
import { DropdownPicker, Input, Switch } from "@eitje/form-fields-web";
import _ from "lodash";
import SimpleList from "components/simple_list";
import {
	useBoolState,
	useMergeState,
	useConcatState,
} from "@eitje/react-hooks";
import { FormSubmitButton } from "components/form";
import moment from "moment";
import {
	Contact,
	ReceivedMail,
	FollowUp,
	PlannedMail,
	SentMail,
	Campaign,
	Outreach,
} from "models/index";
import useShow from "hooks/use_show";
import { ModalLink, NavLink, Link, LinkBtn } from "components/routing";
import { Button, ConfirmButton } from "components/common";
import Modal from "components/modal";
import utils from "@eitje/web_utils";
import MailSequenceForm from "mixins/send_mails/mail_sequence_form";
import { copy } from "utils/utils";
import "./styles/campaign_form.less";

const CampaignForm = ({
	name,
	onSubmit,
	type,
	children,
	labels = [],
	afterSubmit = _.noop,
	initialValues = {},
	...rest
}) => {
	const [customLabels, addCustomLabel] = useConcatState([]);
	const form = useRef(null);
	const _submit = (templateMails) => {
		if (!form.current.validate()) return;
		const data = form.current.getParams();
		return onSubmit({
			...data,
			template_mails_attributes: templateMails.map((m, idx) => ({
				...m,
				position: idx + 1,
			})),
		});
	};

	const initialMails =
		initialValues && _.orderBy(initialValues.template_mails, "position");

	const { id } = initialValues;
	const allLabels = [...labels, ...customLabels];
	return (
		<div className="edit-sequence-page-mixin">
			<div className="sequence-info">
				<ContextForm {...rest} initialValues={initialValues} ref={form}>
					<div className="sequence-name">
						<h4 className="title-sequence-type">{type}</h4>
						<Input field="name" required />
					</div>

					{type == "follow_up" && (
						<div className="sequence-label">
							<DropdownPicker
								dropdownRender={(menu) => renderDropdown(menu, addCustomLabel)}
								required
								field="label"
								label="label"
								items={allLabels}
							/>
						</div>
					)}

					{type == "follow_up" && (
						<div className="action-label">
							<DropdownPicker
								field="auto_action"
								label="auto action"
								allowClear
								items={["to_blacklist", "to_hubspot"]}
							/>
						</div>
					)}

					{children}
				</ContextForm>

				{type == "follow_up" && (
					<ContextForm
						initialValues={initialValues}
						onSubmit={(data) => API.update("follow_ups", data)}
						className="form-set-active"
					>
						<Switch submitStrategy="change" field="active" />
					</ContextForm>
				)}

				{id && (
					<ContextForm
						className="form-send-test"
						onSubmit={(data) =>
							backend.post(`seqs/${id}/example`, { seqs: data })
						}
						initialValues={{ email_address: "tiemo@eitje.app" }}
						resetAfterSubmit
					>
						<Input
							field="email_address"
							label="test email address"
							placeholder="..."
						/>
						<FormSubmitButton
							className="button-send-test poached-secondary-button"
							text="send test"
						/>
					</ContextForm>
				)}
			</div>
			<MailSequenceForm
				initialValues={initialMails}
				onSubmit={_submit}
				afterSubmit={afterSubmit}
				name={name}
			/>
			<Placeholders />
		</div>
	);
};

const renderDropdown = (menu, addName) => {
	return (
		<>
			{menu}
			<ContextForm onSubmit={(data) => addName(data.name)}>
				<div className="label-dropdown-contents">
					<Input field="name" placeholder="new label..." />
					<FormSubmitButton className="poached-secondary-button" />
				</div>
			</ContextForm>
		</>
	);
};

const Placeholders = () => {
	const placeholders = useSelector(
		(state) => state.general.taxonomy.placeholders
	);
	return (
		<div className="placeholders">
			<h3 className="placeholders-title"> Placeholders </h3>
			<SimpleList items={placeholders} Item={Placeholder} />
		</div>
	);
};

const Placeholder = ({ item }) => {
	return (
		<div className="placeholder-item">
			<p className="placeholder-item-content" onClick={() => copy(item)}>
				{item}
			</p>
		</div>
	);
};

export default CampaignForm;
