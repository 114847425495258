import React, { useEffect } from "react";
import { Route, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { API } from "@eitje/easy_api";
import Dropdown from "components/dropdown";
import { Link, NavLink } from "components/routing";
import "./styles/header.less";
import { Spin } from "antd";

const routes = [
	"inbox",
	"auto_mail",
	"contacts",
	"outreaches",
	"follow_ups",
	"data",
];

const settingLinks = [
	<Link to="blacklist" />,
	<Link to="spamlist" />,
	<Link to="snippets" />,
	<Link to="cleanse_contacts" />,
];

const Header = () => {
	const loading = useSelector((state) => state.api.loading);
	return (
		<div className="header">
			{loading && <Spin size="medium" spinning={loading} />}
			<div className="header-items">
				{routes.map((r) => (
					<NavLink className="header-item" to={`${r}`} />
				))}
				<Dropdown elements={settingLinks}>
					<p className="header-item"> settings </p>
				</Dropdown>
			</div>
		</div>
	);
};

export default Header;
