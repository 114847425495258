import { API, filterRecords } from "@eitje/easy_api";
import utils from "@eitje/web_utils";

const validateItems = (items) => {
	if (process.env.NODE_ENV == "production") return;
	// if (!items.every(i => i.activeRecordDescendant)) throw new Error('Association must be initialized with model instances')
	if (items.map((i) => i.constructor.name).uniq().length > 1)
		throw new Error("All assoc children must be of same type");
};

class Association extends Array {
	constructor(props) {
		const items = utils.alwaysArray(props);
		validateItems(items);
		super(...items);
	}

	static get [Symbol.species]() {
		return this;
	}

	where(query) {
		return filterRecords(this, query);
	}

	first = () => this[0];
	last = () => this[this.length - 1];

	tableName = () => this.first().tableName;
}

export default Association;
