import React, { Fragment, useState, useEffect } from "react";
import { Route, Navigate, Outlet, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { API } from "@eitje/easy_api";
import { useForm, ContextForm } from "@eitje/form";
import { DropdownPicker, Input } from "@eitje/form-fields-web";
import _ from "lodash";
import SimpleList from "components/simple_list";
import { useBoolState } from "@eitje/react-hooks";
import { FormSubmitButton } from "components/form";
import moment from "moment";
import { Contact, ReceivedMail, PlannedMail, SentMail } from "models/index";
import useShow from "hooks/use_show";
import { ModalLink } from "components/routing";
import { Button, ConfirmButton } from "components/common";
import Emails from "./emails";
import FollowUpComponent from "./follow_up";
import "./styles/index.less";

const ContactActions = ({ contact, mail }) => {
	const { emails = [] } = contact;
	const [email, setEmail] = useState(emails[0]);
	useEffect(() => {
		setEmail(mail.sender || emails[0]);
	}, [contact.id, emails.length]);

	const hubspot = contact.status == "hubspot";

	const plannedMails = PlannedMail.where({ contact_id: contact.id });
	if (plannedMails.length > 0) return null;
	return (
		<div className="contact-actions">
			<Emails email={email} setEmail={setEmail} emails={emails} />
			{!hubspot && <FollowUpComponent email={email} contact={contact} />}
		</div>
	);
};

export default ContactActions;
