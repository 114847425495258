import React, { Fragment, useEffect } from "react";
import { Route, Navigate, Outlet, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { API } from "@eitje/easy_api";
import { useForm, ContextForm } from "@eitje/form";
import { DropdownPicker, Input } from "@eitje/form-fields-web";
import _ from "lodash";
import SimpleList from "components/simple_list";
import { useBoolState } from "@eitje/react-hooks";
import { Button } from "components/common";
import { FormSubmitButton, LocationResetter } from "components/form";
import moment from "moment";
import { Contact, ReceivedMail, PlannedMail, SentMail } from "models/index";
import useShow from "hooks/use_show";
import { ModalLink } from "components/routing";
import { ConfirmButton } from "components/common";
import { addToInternalBlacklist } from "cores/mail/actions";
import "./styles/contact_header.less";

const ContactHeader = ({ item }) => {
	const { name, id, status, statusChangeAllowed } = item;
	const params = { id };
	return (
		<div className="contact-header">
			<ContactInfo item={item} />
			<ContextForm
				onSubmit={(data) => API.update("contacts", data)}
				initialValues={item}
			>
				<Input
					placeholder="Interpolated name"
					field="first_name"
					labelVisible={false}
				/>
				<FormSubmitButton />
				<LocationResetter />
			</ContextForm>
			{statusChangeAllowed && statusChangeAllowed() ? (
				<ActionButtons id={id} />
			) : (
				<h4 className="contact-status">{status} </h4>
			)}
		</div>
	);
};

const ContactInfo = ({ item }) => {
	const { name } = item;
	return (
		<div className="contact-info">
			<h3 className="contact-name"> {name} </h3>
			<ModalLink to={`/app/contacts/${item.id}`}>
				<Button> info </Button>
			</ModalLink>
		</div>
	);
};

export const SpamButton = (email) => (
	<ConfirmButton onClick={() => addToInternalBlacklist(email)}>
		Spam
	</ConfirmButton>
);

const ActionButtons = ({ id }) => {
	const email = ReceivedMail.where({ contact_id: id }).first()?.sender;
	const doReq = (endpoint) => API.resourceReq("contacts", endpoint, { id });
	return (
		<div className="contact-header-buttons">
			<ConfirmButton onClick={() => doReq("hubspot")}>Hubspot</ConfirmButton>
			<ConfirmButton onClick={() => doReq("blacklist")}>
				Blacklist
			</ConfirmButton>
			<SpamButton email={email} />
		</div>
	);
};

export { ContactInfo };

export default ContactHeader;
