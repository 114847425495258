const nodeStage = process.env.NODE_ENV;
const envStage = process.env.REACT_APP_STAGE;

const stage = envStage || nodeStage;

const prefEnv = "development";

const actualEnv = nodeStage === "development" ? envStage || prefEnv : stage;
const ENV = actualEnv;
let domain;
let protocol = "https://";
switch (actualEnv) {
	case "development":
		domain = "localhost:3010/api/";
		protocol = "http://";
		break;
	case "test":
		domain = "poached-api.eitje.app/api"; // no test yet
		break;
	case "staging":
		domain = "poached-api-staging.eitje.app/api/";
		protocol = "https://";
		break;
	case "production":
		domain = "poached-api.eitje.app/api/";
		protocol = "https://";
		break;
	default:
		domain = "localhost:3010/api";
}

const url = `${protocol}${domain}`;

export { url, ENV };
