import React, { Fragment, useEffect, useRef, useState } from "react";
import { Route, Navigate, Outlet, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { API } from "@eitje/easy_api";
import _ from "lodash";
import SimpleList from "components/simple_list";
import { useBoolState, useIncrementState } from "@eitje/react-hooks";
import { FormSubmitButton } from "components/form";
import moment from "moment";
import {
  Contact,
  ReceivedMail,
  FollowUp,
  PlannedMail,
  SentMail,
  Campaign,
  Outreach,
} from "models/index";
import useShow from "hooks/use_show";
import { ModalLink, NavLink, Link, LinkBtn } from "components/routing";
import { Button, ConfirmButton } from "components/common";
import Modal from "components/modal";
import utils from "@eitje/web_utils";
import { useForm, useMultiForm, ContextForm, MultiForm } from "@eitje/form";
import { DropdownPicker, Input } from "@eitje/form-fields-web";
import useGet from "hooks/use_get";
import "./styles/index.less";

const CleanseContacts = () => {
  const [submitCount, incrementCount] = useIncrementState(1);
  const { data, loading } = useGet(`contacts/unchecked`, {
    watch: submitCount,
  });
  const { items = [], total_count, cleansed_count } = data;

  const onSubmit = async (data) => {
    const toSubmit = data
      .filter((d) => d.cleansed_name)
      .map((d) => ({
        id: d.id,
        name: d.cleansed_name,
        name_checked: true,
      }));

    const res = await API.updateMulti("contacts", toSubmit);
    if (res.ok) incrementCount();
  };

  if (loading) return <p> loading.. </p>;
  return (
    <div className="cleanse-contacts">
      <h3 className="cleans-contacts-count">
        {cleansed_count} / {total_count} cleansed
      </h3>
      <TableHeader />

      <CleanseForm onSubmit={onSubmit} contacts={items} />
    </div>
  );
};

const TableHeader = () => (
  <div className="cleanse-contacts-header">
    <p> name </p>
    <p> current name </p>
    <p> url </p>
  </div>
);

const CleanseForm = ({ contacts, onSubmit }) => {
  contacts = contacts.map((c) => ({ ...c, cleansed_name: c.name }));
  const multiForm = useRef(null);
  return (
    <div className="cleanse-form">
      <MultiForm
        amtForms={contacts.length}
        ref={multiForm}
        onSubmit={onSubmit}
        hideControls
        initialValues={contacts}
      >
        <Input field="cleansed_name" />
        <OtherFields />
      </MultiForm>
      <Button
        className="cleanse-button"
        onClick={() => multiForm.current.submit()}
      >
        submit
      </Button>
    </div>
  );
};

const OtherFields = () => {
  const { getData } = useForm();
  const { name, url } = getData();
  const _url =
    url && !url.includes("https://") && !url.includes("http://")
      ? `https://${url}`
      : url;
  return (
    <Fragment>
      <p className="non-cleansed-name"> {name} </p>
      {url && (
        <a className="contact-url" target="_blank" href={_url}>
          {url}
        </a>
      )}
    </Fragment>
  );
};

export default CleanseContacts;
