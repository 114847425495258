import moment from "moment";
import { API, useWhere, useFind } from "@eitje/easy_api";
import queryMethodsMixin from "./query_methods";
import actionsMixin from "./actions";
import utils from "@eitje/web_utils";

class ActiveRecord {
	constructor(props) {
		Object.keys(props).map((prop) => (this[prop] = props[prop]));
		// this.class = this.constructor.name
	}

	tableName = this.constructor.tableName;

	snakeTableName() {
		return utils.camelToSnake(this.tableName);
	}

	static snakeTableName() {
		return utils.camelToSnake(this.tableName);
	}

	createdAt() {
		return moment(this.created_at);
	}

	updatedAt() {
		return moment(this.updated_at);
	}

	klass() {
		return this.constructor.name;
	}

	activeRecordDescendant = true;
}

ActiveRecord = actionsMixin(ActiveRecord);
ActiveRecord = queryMethodsMixin(ActiveRecord);

export default ActiveRecord;
