import React, { Fragment, useEffect } from "react";
import { Route, Navigate, Outlet, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { API } from "@eitje/easy_api";
import { useForm, ContextForm } from "@eitje/form";
import { DropdownPicker, Input } from "@eitje/form-fields-web";
import _ from "lodash";
import SimpleList from "components/simple_list";
import { useBoolState } from "@eitje/react-hooks";
import { FormSubmitButton } from "components/form";
import moment from "moment";
import {
	Contact,
	ReceivedMail,
	FollowUp,
	PlannedMail,
	SentMail,
} from "models/index";
import useShow from "hooks/use_show";
import { ModalLink, NavLink, Link } from "components/routing";
import { Button, ConfirmButton } from "components/common";
import { useInboxContext } from "cores/mail/inbox/index";
import SimpleGroupedList from "components/simple_grouped_list";
import "./styles/follow_up.less";

const FollowUpComponent = ({ contact, email }) => {
	const { followUpIds } = useInboxContext();
	const followUps = FollowUp.where(followUpIds);

	const groupedFollowUps = _.groupBy(followUps, "label");
	const keys = Object.keys(groupedFollowUps).sort();
	return (
		<div id="follow-ups" className="contact-actions-list">
			{followUps.length > 0 && (
				<h4 className="contact-actions-title"> Follow up </h4>
			)}
			{keys.map((k) => (
				<FollowUpGroup email={email} label={k} items={groupedFollowUps[k]} />
			))}
		</div>
	);
};

const FollowUpGroup = ({ items, label, email }) => {
	const [expanded, toggleExpanded] = useBoolState(false);
	const classNames = [
		"follow-up-group",
		expanded && "follow-up-group-expanded",
	];
	items = _.orderBy(items, "name");

	return (
		<div className={classNames} onClick={toggleExpanded}>
			<p className="contact-actions-item"> {label} </p>
			{expanded && (
				<SimpleList items={items} Item={FollowUpItem} itemProps={{ email }} />
			)}
		</div>
	);
};

const FollowUpItem = ({ item, email }) => {
	const { contact, receivedMail } = useInboxContext();
	const { name, template_mail_count, id } = item;

	const onClick = (e) => {
		item.resourceReq("quick_start", {
			contact_id: contact.id,
			received_mail_id: receivedMail.id,
			email_address: email,
		});
	};
	return (
		<Link
			to={`/app/contacts/${contact.id}/received_mails/${receivedMail.id}/follow_up/${id}?email=${email}`}
		>
			<div className="follow-up-container">
				<div
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
					}}
				>
					<ConfirmButton
						onClick={onClick}
						type="secondary"
						className="follow-up-button"
					>
						send
					</ConfirmButton>
				</div>
				<p className="follow-up-text">
					{name} ({template_mail_count})
				</p>
			</div>
		</Link>
	);
};

export default FollowUpComponent;
