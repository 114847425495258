import React, { useEffect, useState, useLayoutEffect } from "react";
import ReactDOM from "react-dom";
import "initializers/moment";
import "initializers/i18n";
import "initializers/form";
import "initializers/pluralize";
import "initializers/array";
import "antd/dist/antd.min.css";
import "styles/general.css";

import "moment/locale/nl";
import "models/index";
import { init } from "initializers/api";
import App from "./App";
import { Provider } from "react-redux";
import createStore from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { createBrowserHistory } from "history";
import { BrowserRouter, useLocation, Router } from "react-router-dom";

export const { store, persistor } = createStore();

export const history = createBrowserHistory();

init();

const ScrollToTop = ({ children }) => {
  const loc = useLocation();
  useEffect(() => {
    const isModalRoute = !!loc?.state?.background;
    if (!isModalRoute) window.scrollTo(0, 0);
  }, [loc]);
  return children;
};

const CustomRouter = ({ history, ...props }) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(() => history.listen(setState), [history]);

  return (
    <Router
      {...props}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  );
};

const render = () => {
  return ReactDOM.render(
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <CustomRouter history={history}>
          <ScrollToTop>
            <App />
          </ScrollToTop>
        </CustomRouter>
      </PersistGate>
    </Provider>,
    document.getElementById("root")
  );
};

window.onload = () => {
  render();
};

if (module.hot) {
  module.hot.accept("./App", () => {
    render();
  });
}
