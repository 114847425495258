import React, { Fragment, useEffect } from "react";
import { Route, Navigate, Outlet, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { API } from "@eitje/easy_api";
import { useForm, ContextForm } from "@eitje/form";
import { DropdownPicker, Input } from "@eitje/form-fields-web";
import _ from "lodash";
import SimpleList from "components/simple_list";
import { useBoolState } from "@eitje/react-hooks";
import { FormSubmitButton } from "components/form";
import moment from "moment";
import { Contact, ReceivedMail, PlannedMail, SentMail } from "models/index";
import useShow from "hooks/use_show";
import { ModalLink, ModalLinkBtn } from "components/routing";
import { Button, ConfirmButton } from "components/common";
import ReactHoverObserver from "react-hover-observer";
import { Link } from "components/routing";
import { useInboxContext } from "../index";
import utils from "@eitje/web_utils";
import "./styles/emails.less";

const Emails = ({ emails, setEmail, email }) => {
	return (
		<div className="reply-emails-list contact-actions-list">
			<h4 className="contact-actions-title"> Emails </h4>
			<SimpleList items={emails} Item={Email} itemProps={{ setEmail, email }} />
		</div>
	);
};

const Email = ({ item, ...rest }) => {
	return (
		<ReactHoverObserver className="reply-email-list-inner">
			<EmailInner item={item} {...rest} />
		</ReactHoverObserver>
	);
};

const EmailInner = ({ item, isHovering, setEmail, email }) => {
	const { contact } = useInboxContext();
	const { receivedMail } = useInboxContext();
	const blacklisted = contact.emailBlacklisted(item);
	const classNames = [
		"reply-email",
		isHovering && "reply-email-hovering",
		email == item && "reply-email-active",
		blacklisted && "reply-email-blacklisted",
	];

	const onClick = blacklisted ? _.noop : () => setEmail(item);

	return (
		<div className onClick={onClick} className={utils.makeCns(classNames)}>
			<p className="contact-actions-item"> {item} </p>

			{isHovering && !blacklisted && (
				<Link
					className="reply-button"
					to={`/app/contacts/${contact.id}/received_mails/${receivedMail.id}/send_blank?email=${item}`}
				>
					<Button> reply </Button>
				</Link>
			)}
		</div>
	);
};

export default Emails;
