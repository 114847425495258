import React, { Fragment, useState, useEffect } from "react";
import ErrorView from "pages/system/err";
import { useLocation } from "react-router-dom";
import { ENV } from "environment";

const ErrorBoundary = ({ children }) => {
  const location = useLocation();
  const [error, setError] = useState(false);

  useEffect(() => {
    setError(null);
  }, [location]);

  if (!error) return children;

  return (
    <Fragment>
      <ErrorView />
      {ENV !== "production" && <p> {error} </p>}
    </Fragment>
  );
};

export default ErrorBoundary;
