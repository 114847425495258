const initialState = {
  expires_at: null,
  refresh_token: null,
  token: null,
  user: {},
};

export default function reduce(state = initialState, action) {
  switch (action.type) {
    case "LOGOUT":
      return initialState;

    case "USERS":
      const user = action.users.find((u) => u.id === state.user.id);
      return {
        ...state,
        user: { ...state.user, ...user },
      };
    case "SET_TOKENS":
      return {
        ...state,
        token: action.token,
        refresh_token: action.refresh_token,
        expires_at: action.expires_at,
      };
    case "AUTH_SUCCESS":
      return {
        ...state,
        refresh_token: action.refresh_token,
        token: action.token,
        expires_at: action.expires_at,
        user: action.user,
      };

    default:
      return state;
  }
}
