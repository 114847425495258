import auth from "reducers/auth";
import reading from "reducers/reading";
import aggregates from "reducers/aggregates";
import { reducer as records, apiReducer } from "@eitje/easy_api";
import general from "reducers/general";

const reducers = {
	auth,
	records,
	reading,
	api: apiReducer,
	aggregates,
	general,
};

export default reducers;
