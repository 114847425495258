import ActiveRecord from "models/active_record/base";
import {
  useWhere,
  useFind,
  useAll,
  useSelector as doSelector,
} from "@eitje/easy_api";

class PlannedMail extends ActiveRecord {
  static tableName = "plannedMails";
  static defaultJoins = ["contacts"];
  constructor(props) {
    super(props);
    this.sortTime = this.sortTime.bind(this);
  }
  sortTime() {
    return this.scheduled_date;
  }
}

export default PlannedMail;
