import React, { useEffect } from "react";
import { Route, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { API } from "@eitje/easy_api";
import { useForm, ContextForm } from "@eitje/form";
import { DropdownPicker, Input } from "@eitje/form-fields-web";
import _ from "lodash";
import SimpleList from "components/simple_list";
import { useBoolState } from "@eitje/react-hooks";
import { Button } from "components/common";
import { FormSubmitButton } from "components/form";
import moment from "moment";
import "./styles/audits.less";

const Audits = ({ item }) => {
	const { audits = [] } = item;
	const sorted = _.orderBy(audits, "created_at", "desc");
	return (
		<div className="contact-audits">
			<h3> Audits </h3>
			<SimpleList items={sorted} Item={Audit} />
		</div>
	);
};

const Audit = ({ item }) => {
	const { created_at, audited_changes = {} } = item;
	const changeKeys = Object.keys(audited_changes);
	return (
		<div className="audit">
			<AuditHeader date={item.created_at} />
			{changeKeys.map((k) => (
				<AuditedChange name={k} changes={audited_changes[k]} />
			))}
		</div>
	);
};

const AuditedChange = ({ changes, name }) => {
	const [was, is] = changes;
	return (
		<div className="audited-change">
			<p> {name} </p>
			<p> {was || "..."} </p>
			<p> {is} </p>
		</div>
	);
};

const AuditHeader = ({ date }) => {
	return (
		<div className="audit-header">
			<h4> {moment(date).format()} </h4>
			<h4> was </h4>
			<h4> became </h4>
		</div>
	);
};

export default Audits;
