import React, { Fragment, useRef, useEffect } from "react";
import { Route, Navigate, Outlet, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { API } from "@eitje/easy_api";
import { useForm, useMultiForm, ContextForm, MultiForm } from "@eitje/form";
import { DropdownPicker, Input } from "@eitje/form-fields-web";
import _ from "lodash";
import SimpleList from "components/simple_list";
import { useBoolState } from "@eitje/react-hooks";
import { FormSubmitButton } from "components/form";
import moment from "moment";
import {
	Contact,
	ReceivedMail,
	FollowUp,
	PlannedMail,
	SentMail,
} from "models/index";
import useShow from "hooks/use_show";
import { ModalLink, NavLink, Link } from "components/routing";
import { Button, ConfirmButton } from "components/common";
import Modal from "components/modal";
import utils from "@eitje/web_utils";
import InputNumber from "components/simple_input_number";
import { useSendMailsContext } from "mixins/send_mails/index";
import "./styles/mail_sequence_form.less";

const _defaultBody = (name) => {
	const greeting = name ? `Hi ${name},\n\n` : "Hi,\n\n";
	const bye = "Groetjes, \n\nTiemo | https://www.eitje.app";
	return `${greeting}\n${bye}`;
};

const MailSequenceForm = ({
	onSubmit = _.noop,
	initialValues = [],
	name,
	setMultiForm,
	defaultBody = _defaultBody,
	...rest
}) => {
	const multiForm = useRef(null);

	const initialValue = { body: defaultBody(name), days_till_next: 10 };

	useEffect(() => {
		if (multiForm.current && setMultiForm) setMultiForm(multiForm);
	}, [multiForm.current]);

	return (
		<div className="send-mails-list">
			<MultiForm
				maxForms={10}
				hideAddButton={false}
				initialValue={initialValue}
				ref={multiForm}
				onSubmit={onSubmit}
				hideControls
				amtForms={initialValues.length || 1}
				initialValues={initialValues}
				{...rest}
			>
				{({ idx, addForm, removeForm, ...rest }) => {
					return (
						<div className="single-mail-form">
							<h3 className="single-mail-form-title" onClick={addForm}>
								Mail {idx + 1}
							</h3>
							<MailInput />
							<DaysTillNext idx={idx} />
							<RemoveFormButton removeForm={removeForm} />
						</div>
					);
				}}
			</MultiForm>

			<Button
				className="button-submit-mails"
				onClick={() => multiForm.current.submit()}
			>
				submit
			</Button>
		</div>
	);
};

const RemoveFormButton = ({ removeForm }) => {
	const { getData } = useForm();
	const { id } = getData();
	if (id) return null;
	return (
		<Button
			className="button-remove-mail poached-secondary-button"
			onClick={removeForm}
		>
			remove
		</Button>
	);
};

const MailInput = (props) => {
	return <Input autoSize required textarea field="body"></Input>;
};

const DaysTillNext = ({ idx }) => {
	const { getData } = useMultiForm();
	return (
		<InputNumber
			min={1}
			required
			label="days till next"
			field="days_till_next"
		/>
	);
};

export default MailSequenceForm;
