import React, { Fragment, useEffect } from "react";
import { Route, Navigate, Outlet, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { API } from "@eitje/easy_api";
import { useForm, ContextForm } from "@eitje/form";
import { DropdownPicker, Input } from "@eitje/form-fields-web";
import _ from "lodash";
import SimpleList from "components/simple_list";
import { useBoolState } from "@eitje/react-hooks";
import { FormSubmitButton } from "components/form";
import moment from "moment";
import {
	Contact,
	ReceivedMail,
	FollowUp,
	PlannedMail,
	SentMail,
	Campaign,
	Outreach,
} from "models/index";
import useShow from "hooks/use_show";
import { ModalLink, NavLink, Link, LinkBtn } from "components/routing";
import { Button, ConfirmButton } from "components/common";
import Modal from "components/modal";
import utils from "@eitje/web_utils";

const ActionButtons = () => {
	return (
		<div className="action-buttons">
			<ConfirmButton
				className="poached-secondary-button"
				onClick={() => API.arbitrary("follow_ups", "resume_all")}
			>
				Resume all
			</ConfirmButton>
			<ConfirmButton
				className="poached-secondary-button"
				onClick={() => API.arbitrary("follow_ups", "pause_all")}
			>
				Pause all
			</ConfirmButton>
			<LinkBtn
				className="poached-secondary-button"
				to="new"
				text="create follow up"
			/>
		</div>
	);
};

export default ActionButtons;
