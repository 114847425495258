import React, { useEffect } from "react";
import { Route, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { API } from "@eitje/easy_api";
import { useForm } from "@eitje/form";
import { DropdownPicker, Input } from "@eitje/form-fields-web";
import { fields } from "./index";
import "./styles/filter.less";

const Filter = () => {
	const { getData, ...rest } = useForm();
	const { filter_column } = getData();

	return (
		<div className="crm-table-filter">
			<DropdownPicker field="filter_column" items={[...fields, "id"]} />
			<Input disabled={!filter_column} field="filter_query" />
		</div>
	);
};

export default Filter;
