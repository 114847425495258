import React, { Fragment, useEffect } from "react";
import { Route, Navigate, Outlet, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { API } from "@eitje/easy_api";
import { useForm, ContextForm } from "@eitje/form";
import { DropdownPicker, Input } from "@eitje/form-fields-web";
import _ from "lodash";
import SimpleList from "components/simple_list";
import { useBoolState } from "@eitje/react-hooks";
import { FormSubmitButton } from "components/form";
import moment from "moment";
import { Contact, ReceivedMail, PlannedMail, SentMail } from "models/index";
import useShow from "hooks/use_show";
import { ModalLink } from "components/routing";
import { Button, ConfirmButton } from "components/common";
import { store } from "index";

const addToInternalBlacklist = async (email) => {
	const res = await API.create("internalBlacklist", email);
	if (res.ok) {
		API.index("received_mails");
	}
};

export { addToInternalBlacklist };
