import { createStore } from "redux";
import reducers from "./reducers";
import Reactotron from "initializers/reactotron.js";
import { persistCombineReducers, persistStore } from "redux-persist";
import Localforage from "localforage";

const config = {
  key: "poached-web",
  storage: Localforage,
  blacklist: ["local"],
};

const persistedReducers = persistCombineReducers(config, reducers);

const rootReducer = (state, action) => {
  return persistedReducers(state, action);
};

let store;

if (process.env.NODE_ENV == "development") {
  store = createStore(rootReducer, undefined, Reactotron.createEnhancer());
} else {
  store = createStore(rootReducer, undefined);
}

if (process.env.NODE_ENV !== "production") {
  if (module.hot) {
    module.hot.accept("./reducers", () => {
      store.replaceReducer(rootReducer);
    });
  }
}

const persistor = persistStore(store, null, () => {
  store.getState();
});
const getVars = () => ({ store, persistor });
export default getVars;
