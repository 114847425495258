import React, { Fragment, useEffect } from "react";
import { Route, Navigate, Outlet, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { API, useGet } from "@eitje/easy_api";
import { useForm, ContextForm } from "@eitje/form";
import { DropdownPicker, Input } from "@eitje/form-fields-web";
import _ from "lodash";
import SimpleList from "components/simple_list";
import { useBoolState } from "@eitje/react-hooks";
import { FormSubmitButton } from "components/form";
import moment from "moment";
import {
	Contact,
	ReceivedMail,
	FollowUp,
	PlannedMail,
	SentMail,
} from "models/index";
import useShow from "hooks/use_show";
import { ModalLink, NavLink, Link } from "components/routing";
import { Button, ConfirmButton } from "components/common";
import Modal from "components/modal";
import utils from "@eitje/web_utils";
import SendMails from "mixins/send_mails/index";
import { Spin } from "antd";
import "./styles/index.less";

const Send = () => {
	const { id, follow_up_id } = useParams();
	const { data, loading } = useGet(
		`follow_ups/${follow_up_id}/fill_templates/${id}`
	);
	if (loading) return <Spin spinning />;
	return (
		<div className="send-follow-up-page">
			<SendMails
				mergeData={{ id: follow_up_id, contact_id: id }}
				kind="follow_ups"
				endPoint="start"
				initialValues={_.orderBy(data, "position")}
				hideSingleMails
			/>
		</div>
	);
};

export default Send;
