import React, { Fragment, useEffect } from "react";
import { Route, Navigate, Outlet, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { API, backend } from "@eitje/easy_api";
import { useForm, ContextForm } from "@eitje/form";
import { DropdownPicker, Input } from "@eitje/form-fields-web";
import _ from "lodash";
import SimpleList from "components/simple_list";
import { useBoolState } from "@eitje/react-hooks";
import { FormSubmitButton } from "components/form";
import moment from "moment";
import {
	Contact,
	ReceivedMail,
	FollowUp,
	PlannedMail,
	SentMail,
} from "models/index";
import useShow from "hooks/use_show";
import { ModalLink, NavLink, Link } from "components/routing";
import { Button, ConfirmButton } from "components/common";
import Modal from "components/modal";
import utils from "@eitje/web_utils";
import { store } from "index";

export const getAggregates = async () => {
	const res = await backend.get(`outreaches/aggregates`);
	if (res.ok) {
		store.dispatch({ type: "SET_AGGREGATES", aggregates: res.data.aggregates });
	}
};

export const getFollowUpAggregates = async () => {
	const res = await backend.get(`follow_ups/aggregates`);
	if (res.ok) {
		store.dispatch({ type: "SET_AGGREGATES", aggregates: res.data.aggregates });
	}
};
