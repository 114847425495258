import React, { Fragment, useEffect } from "react";
import { Route, Navigate, Outlet, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { API } from "@eitje/easy_api";
import { useForm, ContextForm } from "@eitje/form";
import { DropdownPicker, Input } from "@eitje/form-fields-web";
import _ from "lodash";
import SimpleList from "components/simple_list";
import { useBoolState } from "@eitje/react-hooks";
import { FormSubmitButton } from "components/form";
import moment from "moment";
import {
	Contact,
	ReceivedMail,
	FollowUp,
	PlannedMail,
	SentMail,
} from "models/index";
import useShow from "hooks/use_show";
import { ModalLink, NavLink, Link } from "components/routing";
import { Button, ConfirmButton } from "components/common";
import Modal from "components/modal";
import utils from "@eitje/web_utils";
import "./styles/simple_grouped_list.less";

const SimpleGroupedList = ({
	items,
	Item,
	Group = DefaultGroup,
	groupKey = "label",
	...rest
}) => {
	const groupedItems = _.groupBy(items, groupKey);
	const labels = Object.keys(groupedItems).sort();
	return (
		<div className="simple-grouped-list">
			{labels.map((l) => (
				<Group Item={Item} label={l} items={groupedItems[l]} {...rest} />
			))}
		</div>
	);
};

const DefaultGroup = ({ label, items, Item, itemProps = {} }) => {
	const [expanded, toggleExpanded] = useBoolState(false);
	const classNames = utils.makeCns([
		"grouped-list-group",
		expanded && "grouped-list-group-expanded",
	]);

	return (
		<div onClick={toggleExpanded} className={classNames}>
			<p className="grouped-list-group-title"> {label} </p>
			{expanded && (
				<SimpleList itemProps={itemProps} items={items} Item={Item} />
			)}
		</div>
	);
};

export default SimpleGroupedList;
