import _ from "lodash";
import { useState } from "react";

const getValue = (item, { sortField, nested }) => {
  let val = _.get(item, sortField);
  if (nested) val = val.value;
  if (_.isString(val)) val = val.toLowerCase();
  return val;
};

export const sortItems = (items, props = {}) => {
  const { sortField, sortFuncs = {}, asc } = props;
  let _items = items;

  if (sortField) {
    const order = asc ? "asc" : "desc";
    if (sortFuncs[sortField]) return sortFuncs[sortField](items);
    const falsyItems = items.filter((i) => !getValue(i, props));
    const truthyItems = items.filter((i) => !!getValue(i, props));
    _items = [
      ..._.orderBy(truthyItems, (item) => getValue(item, props), order),
      ...falsyItems,
    ];
  }
  return _items;
};

const useSort = ({ items, fields = [], defSortField, defOrder, ...rest }) => {
  const [sortField, setSortField] = useState(defSortField);
  const [asc, setAsc] = useState(defOrder === "desc" ? false : true);
  let sortedItems = items;
  const changeSort = (newField) => {
    if (sortField === newField) {
      setAsc(!asc);
      return;
    }
    setSortField(newField);
    setAsc(true);
  };

  if (fields.length > 0) {
    sortedItems = fields.includes(sortField)
      ? sortItems(items, { sortField, asc, ...rest })
      : items;
  }

  return { changeSort, sortedItems, asc, sortField };
};

export default useSort;
