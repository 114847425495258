import React, { useEffect } from "react";
import { Route, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { API } from "@eitje/easy_api";
import { useForm, ContextForm } from "@eitje/form";
import { DropdownPicker, Input } from "@eitje/form-fields-web";
import _ from "lodash";
import SimpleList from "components/simple_list";
import { useBoolState } from "@eitje/react-hooks";
import { Button } from "components/common";
import { FormSubmitButton } from "components/form";
import moment from "moment";
import "./styles/notes.less";

const Notes = ({ item }) => {
	const { notes } = item;
	const [noteFormVisible, toggleNoteFormVisible] = useBoolState();
	const noteItems = _.orderBy(notes, "created_at", ["desc"]);
	return (
		<div className="contact-notes">
			<h3> Notes </h3>
			<SimpleList items={notes} Item={Note} />
			<Button
				className="button-add-note poached-secondary-button"
				onClick={toggleNoteFormVisible}
			>
				add
			</Button>
			{noteFormVisible && (
				<NoteForm id={item.id} hide={toggleNoteFormVisible} />
			)}
		</div>
	);
};

const NoteForm = ({ hide, id }) => {
	return (
		<ContextForm
			afterSubmit={hide}
			onSubmit={(data) =>
				API.create("notes", {
					...data,
					contact_id: id,
				})
			}
		>
			<Input required field="note" />
			<FormSubmitButton className="poached-secondary-button" />
		</ContextForm>
	);
};

const Note = ({ item }) => {
	const { created_at, note } = item;
	return (
		<div className="note">
			<h4 className="note-timestamp">
				{moment(item.created_at).format("HH:mm [at] DD/MM/YY")}{" "}
			</h4>
			<p className="note-body"> {note} </p>
		</div>
	);
};

export default Notes;
