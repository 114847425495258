import React, { useEffect } from "react";
import { Route, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { API } from "@eitje/easy_api";
import { useForm, ContextForm } from "@eitje/form";
import { DropdownPicker, Input } from "@eitje/form-fields-web";
import _ from "lodash";
import "./styles/simple_table.less";

const SimpleTable = ({ item }) => {
	const keys = Object.keys(item);
	return (
		<div className="simple-table">
			{keys.map((k) => (
				<Cell objectKey={k} objectValue={item[k]} />
			))}
		</div>
	);
};

const Cell = ({ objectKey, objectValue }) => {
	if (_.isArray(objectValue)) objectValue = objectValue.join(", ");
	if (_.isObject(objectValue)) return null;
	return (
		<div className="simple-cell">
			<p className="object-key"> {objectKey} </p>
			<p className="object-value"> {objectValue} </p>
		</div>
	);
};

export default SimpleTable;
